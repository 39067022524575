import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import {  kycDetailsWithMAIDsReqPayload, kycDetailsWithMAIDsResponse } from '../interfaces/familyMemberDetails.interface';
import { kycDetailsApi } from '../api/familyMemberDetailsAPI';
import { abhaIds } from '../api/homeAPI';
import { abhaIdsRequest, abhaIdsResponse } from '../interfaces/abha.interface';

export interface abhaIdsState {
  value: abhaIdsResponse | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: abhaIdsState = {
  value: null,
  status: 'idle',
};

export const abhaIdsAsync = createAsyncThunk(
  "abha/abhaIdsAsync",
  async (req: abhaIdsRequest) => {
    const response = await abhaIds(req);
    return response.data;
  }
);

export const abhaSlice = createSlice({
  name: 'abha',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(abhaIdsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(abhaIdsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(abhaIdsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = abhaSlice.actions;
export const getAbhaIdsDetails = (state: RootState) => state.abhaDetails.value;
export const getAbhaIdsLoading = (state: RootState) => state.abhaDetails.status === 'loading';

export default abhaSlice.reducer;
