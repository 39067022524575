import { useNavigate } from "react-router";
import { useAppDispatch } from "../app/hooks";
import { loginSsoSaml } from "./SSO"
import { useEffect } from "react";
import { getAccessToken } from "../utils";
import Loader from "./shared/Loader";
const Genlog = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const callback = new URLSearchParams(window.location.search).get('callback') as string;

    useEffect(()=>{
        const url = window.location;
        const token = new URLSearchParams(url.search).get('token') as string;
        const username = new URLSearchParams(url.search).get('userName') as string;

        if(getAccessToken() && !callback){
            navigate("/")
         }
        const req = { token };
        loginSsoSaml(req, dispatch, callback, username)
    },[])

    return (
        <>
        <Loader/>
        </>
    )
}

export default Genlog;