import React, { useState } from 'react'


declare global {
	namespace JSX {
		interface IntrinsicElements {
			'cis-web-component': any;
		}
	}
}

const CIS = () => {
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const encToken = query.get('encToken')
    return (
        <>
            <cis-web-component
                encToken={encToken}
            >
            </cis-web-component>
        </>
    )
}

export default CIS;