import { ILogDto, LogParameter } from "../utils/log.dto";
import { LoggerConstant  } from "./common.constants";
interface IRequestOrResponse<T = any> {
  [key: string]: T | any;
}
export const getLoggerDTO = (
  response: IRequestOrResponse,
  request: IRequestOrResponse,
  eventName?: string,
  initialLogDto?: ILogDto
) => {
  const logDataRequest: ILogDto = {} as ILogDto;
  const res = response.data;
  switch (eventName) {
    case LoggerConstant.USERNAME_PASSWORD_LOGIN:
      logDataRequest.responseTime = new Date().toISOString();
      logDataRequest.response = JSON.stringify(response);
      if (res?.redirectUrl) {
        logDataRequest.field4 = LoggerConstant.SUCCESS;
        logDataRequest.field1 = LoggerConstant.REDIRECT_TO_SSO;
      } else if (res?.accessToken) {
        logDataRequest.field4 = LoggerConstant.SUCCESS;
      } else if (res?.message && request?.password) {
        logDataRequest.field4 = LoggerConstant.FAIL;
        logDataRequest.field6 =
          (res?.message && res?.message[0]) || LoggerConstant.NETWORK_DISCONNECTED;
      }
      break;
    case LoggerConstant.SSO_LOGIN:
      logDataRequest.responseTime = new Date().toISOString();
      logDataRequest.response = JSON.stringify(response);
      if (res?.redirectUrl) {
        logDataRequest.field4 = LoggerConstant.SUCCESS;
        logDataRequest.field1 = LoggerConstant.REDIRECT_TO_SSO;
      } else if (res?.accessToken) {
        logDataRequest.field4 = LoggerConstant.SUCCESS;
      } else if (res?.message && request?.password) {
        logDataRequest.field4 = LoggerConstant.FAIL;
        logDataRequest.field6 =
          (res?.message && res?.message[0]) || LoggerConstant.NETWORK_DISCONNECTED;
      }
      break;
    case LoggerConstant.SEND_OTP:
      logDataRequest.responseTime = new Date().toISOString();
      logDataRequest.response = JSON.stringify(response);
      if (res?.redirectUrl) {
        logDataRequest.field4 = LoggerConstant.SUCCESS;
        logDataRequest.field1 = LoggerConstant.REDIRECT_TO_SSO;
        return;
      } else if (res?.encryptedToken) {
        logDataRequest.field4 = LoggerConstant.SUCCESS;
        logDataRequest.field1 = LoggerConstant.OTP_SENT;
      } else if (res?.accessToken) {
      } else {
        logDataRequest.field4 = LoggerConstant.FAIL;
        logDataRequest.field6 =
          (res?.message && res?.message[0]) || LoggerConstant.NETWORK_DISCONNECTED;
      }
      break;
    case LoggerConstant.OTP_VERIFICATION:
      logDataRequest.responseTime = new Date().toISOString();
      logDataRequest.response = JSON.stringify(response);
      if (res?.accessToken) {
        logDataRequest.field1 = LoggerConstant.OTP_VERIFIED_SUCCESS;
        logDataRequest.field4 = LoggerConstant.SUCCESS;
      }
      if (res?.statusCode === 202) {
        logDataRequest.field4 = LoggerConstant.SUCCESS;
        logDataRequest.field1 = LoggerConstant.OTP_VERIFIED_OR_USER_NOT_FOUND;
      } else if (res?.encryptedToken) {
        logDataRequest.field1 = LoggerConstant.OTP_VERIFIED_OR_MULTI_USER;
        logDataRequest.field4 = LoggerConstant.SUCCESS;
      } else {
        logDataRequest.field4 = LoggerConstant.FAIL;
        logDataRequest.field6 =
          (res?.message && res?.message[0]) || LoggerConstant.NETWORK_DISCONNECTED;
      }
      break;
    default:
      logDataRequest.responseTime = new Date().toISOString();
      logDataRequest.response = JSON.stringify(response);
      logDataRequest.field4 = LoggerConstant.FAIL;
  }
  return { ...initialLogDto, ...logDataRequest };
};
export const getLogAPIStatusDTO = (
  type: string,
  url: string,
  request: any,
  response: any,
  actionPage: string
) => {
  const res = response?.data ? response?.data : response;
  const logsData: LogParameter = {} as LogParameter;
  //Todo: these 5 properties are common in all types , if you need to specify specific data then add a case and add your data
  logsData.isDataLoaded = response?.isSuccess ? response?.isSuccess : false;
  logsData.actionPage = actionPage;
  logsData.requestBody = request ?  JSON.stringify(request) : null;
  logsData.responseBody = res ? JSON.stringify(res) : null;
  logsData.entityName = url;
  switch (type) {
    case LoggerConstant.CLAIM_LIST_PAGE:
      logsData.claimLength = res?.searchResults?.length || null;
      break;
    case LoggerConstant.BENEFICIARY_API:
      logsData.loginBy = `Beneficiary_${sessionStorage.getItem("flowname")}`;
      break;
    case LoggerConstant.BASE_PROFILE_API:
      logsData.loginBy = sessionStorage.getItem("flowname");
      break;
    case LoggerConstant.POLICY:
      logsData.isDataLoaded= response && response?.length >0;
      break;
    default:
      break;
  }
  return logsData;
};