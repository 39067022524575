import { createTicketApi } from "../api/loginAPI";
import { testEmail, testPhoneNumber } from "../common/helpers";
import { TicketTypeEnum, TicketRouteEnum } from "../constants/enum";
import { TicketRequest, TicketResponse } from "../interfaces/login";

/* Ticket Creation Function */
export const createTicket = async (type: TicketTypeEnum, failureRoute: TicketRouteEnum, email: string, user: string, error: string, loginMethod: string, lsKey?: string) => {
  const url = `/freshdesk/create/ticket?route=${failureRoute}&type=${type}`;
  const attemptCount = localStorage.getItem(`${lsKey}`) ? parseInt(localStorage.getItem(`${lsKey}`) as any) : 0;
  const ticketRequest = new TicketRequest();
  ticketRequest.reason = error;
  if (loginMethod === 'USERNAME') {
    ticketRequest.mode = 'Username/password';
    ticketRequest.username = user;
  } else if (testEmail(user) || testPhoneNumber(user)) {
    ticketRequest.mode = testEmail(user) ? 'Email OTP' : 'Mobile OTP';
    ticketRequest.loginEmail = testEmail(user) ? user : undefined;
    ticketRequest.loginMobileNumber = testPhoneNumber(user) ? user : undefined;
  }
  ticketRequest.attemptCount = attemptCount;
  ticketRequest.emailForTicket = email;
  let response = new TicketResponse();
  try {
    // response = await SendRequest.instance.post(url, ticketRequest);
    let res = await createTicketApi(url,ticketRequest);
    response = res.data; 
    if (response && response?.isSuccess && response?.helpDeskTicket) {
      localStorage.removeItem(lsKey as any);
    } else {
      response.isSuccess = false;
    }
  } catch (err) {
    response.isSuccess = false;
  }
  return response;
}