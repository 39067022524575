import React, { useEffect, useState } from "react";
import HeaderWeb from "./shared/Header";
import { useLocation, useNavigate } from "react-router";
import FooterWeb from "./shared/Footer";
import NewKycWebComponent from "./NewKycWebComponent";
import { Helmet } from "react-helmet-async";

const NewKYC = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [benefDetails, setbenefDetails] = useState(null as any);
  useEffect(() => {
    setbenefDetails(location?.state);
  }, [props]);

  return (
    <>
      <Helmet>
        <title>MAven - KYC</title>
        <meta name="description" content="" />
      </Helmet>
      <HeaderWeb isShowBackButton={true} oldHeader={true} name={""} />
      {benefDetails && (
        <div style={{ paddingTop: "75px" }}>
          <NewKycWebComponent
            maid={location?.state?.maid}
            benefName={location?.state?.benefName}
            notify ={props?.notify}
          />
        </div>
      )}
      {/* <FooterWeb /> */}
    </>
  );
};

export default NewKYC;
