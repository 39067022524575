export interface Notification {
  id?: string;
  title?: string;
  maid?: string;
  message?: string;
  action?: string;
  createdOn?: any;
  readOn?: any;
}

export interface NotificationsResponse {
  unreadNotifications: Array<Notification>;
  allNotifications: Array<Notification>;
  message: string[] | string;
  statusCode: number;
  error: string;
}

export class UpdateNotificationsDTO {
  ids: Array<string>;
}

export class UpdateNotificationsResponseDTO{
  error: string;
  message: Array<string>;
  statusCode: number;
}