export class kycDetailsWithMAIDsReqPayload {
  maids!: number[];
}

export interface kycDetailsWithMAIDsResponse {
  isSuccess: Boolean;
  kycDetails: Array<kycDetailsDto>;
  error: any;
  message: any;
}

export interface kycDetailsDto {
  isKycAvailable?: boolean;
  isKycRequired?: boolean;
  isPanAvailable?: boolean;
  maid?: number;
}