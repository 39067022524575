import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { tags } from '../constants/resetPassword';
import { changePasswordWithEmailSendOtp, changePasswordWithMobileSendOtp, resetOldPasswprd, resetPasswordWithOtp } from '../api/resetPasswordAPI';
import { resetOldPasswordPayload } from '../interfaces/resetPassword.interface';
import { useAppSelector } from '../app/hooks';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import { initialTimeout, useTimer } from '../utils/Timer';
import {VerifyOtp as VerifyOtpComponent} from '@mediassistrepo/maven-old'
import HeaderWeb from './shared/Header';
import { getCookieValue, postToNativeApp } from '../common/helpers';

const VerifyOtp = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const baseProfile = useAppSelector(getBaseProfile);
  const [otp, setOtp] = useState(''); // stores otp value
  const [showError, setShowError] = useState(false);
  const [errorSubtitle, setErrorSubtitle] = useState('');
  const [loader, setLoader] = useState(true); // full screen loader
  const [oldPassword, setOldPassword] = useState(''); // stores old password value
  const [newPassword, setNewPassword] = useState(''); // stores new password value
  const [confirmNewPassword, setConfirmNewPassword] = useState(''); // stores confirm password value
  const [otpRequested, setOtpRequested] = useState(true) // holds the status of otp request
  const { seconds, setSeconds } = useTimer(otpRequested);
  const [showModal, setShowModal] = useState(false);

  const resetPassword = async (eventType: string) => {
    var url = '';
    var response;
    if (eventType == tags.password) {
        let start= Date.now();
        const payload: resetOldPasswordPayload = {
            oldPassword: oldPassword,
            newPassword: newPassword,
        }
        try {
            response = await resetOldPasswprd(payload);
        } catch (err) {
        }
        handleResponse(response?.data,start,eventType);
    }
    else {
        let start= Date.now();
        const payload: any = {
            key: otp,
            encryptedToken: location?.state?.encryptedToken,
            newPassword: newPassword,
            emailId:  eventType === tags.email ? baseProfile?.data?.emailId : undefined,
        }
        try {
            response = await resetPasswordWithOtp(payload);
        } catch (err) {
        }
        handleResponse(response?.data,start,eventType);
    }

}

const handleSuccessModalClick = () => {
    navigate("/logout")
}

const handleResponse = (response: any,start:any,eventType:any) => {
    let end,status;
    if (response.isSuccess) {
        end=Date.now();
        status=true;
        setShowModal(true);
        setShowError(false);
    }
    else {
        end=Date.now();
        status=false;
        setErrorSubtitle(response.error);
        setShowError(true);
        resetData();
    }
    setLoader(false);
   let timeTaken=end - start;
   
   if(eventType == tags.password){
    // setGaProperty({
    //     maid:localStorage.getItem("maid"),
    //     validation:status,
    //     apiSuccess:status,
    //     timeTakenResponse:timeTaken +" ms"
    //   })
   }  else if(eventType == tags.mobile){
    // setGaProperty({
    //     maid:localStorage.getItem("maid"),
    //     validation:status,
    //     apiSuccess:status,
    //     timeTakenResponse:timeTaken +" ms"
    //   })
   }else{
    // setGaProperty({
    //     maid:localStorage.getItem("maid"),
    //     validation:status,
    //     apiSuccess:status,
    //     timeTakenResponse:timeTaken +" ms"
    //   })
   }
}

const resendOtp = async (eventType: string) => {
  var response;
  switch (eventType) {
      case tags.mobile:
          try {
              response = await changePasswordWithMobileSendOtp();
          } catch (err) {
          }
          break;

      case tags.email:
          try {
              response = await changePasswordWithEmailSendOtp()
          } catch (err) {
          }
          break;
  }
  resetData();
  if (response?.data?.isSuccess) {
      setLoader(false);
      setShowError(false);
      setSeconds(initialTimeout);

  }
  else {
      setErrorSubtitle(response?.data?.statusCode + ": " + response?.data?.error);
      setLoader(false);
      setShowError(true);
  }
  
}

const resetData = () => {
  setOtp('');
  setNewPassword('');
  setOldPassword('');
  setConfirmNewPassword('');
}
  return (
    <>
    <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Reset Password"} />
    <VerifyOtpComponent 
    eventType={location?.state?.eventType}
    eventValue={location?.state?.eventValue}
    encryptedToken={location?.state?.encryptedToken}
    resetPassword={resetPassword}
    oldPassword={oldPassword}
    newPassword={newPassword}
    otp={otp}
    confirmNewPassword={confirmNewPassword}
    setOtp={setOtp}
    setOldPassword={setOldPassword}
    setNewPassword={setNewPassword}
    setConfirmNewPassword={setConfirmNewPassword}
    showError={showError}
    setShowError={setShowError}
    loader={loader}
    setLoader={setLoader}
    errorSubtitle={errorSubtitle}
    setErrorSubtitle={setErrorSubtitle}
    otpRequested={otpRequested}
    setOtpRequested={setOtpRequested}
    seconds={seconds}
    setSeconds={setSeconds}
    resendOtp={resendOtp}
    handleResponse={handleResponse}
    setShowModal={setShowModal}
    showModal={showModal}
    handleSuccessModalClick={handleSuccessModalClick}
    />
    </>
  )
}

export default VerifyOtp