import React, { useEffect, useState } from 'react';

export const initialTimeout = 60;
const useTimer = (otpRequested:any) => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0 && otpRequested) {
        setSeconds((prevseconds) => prevseconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds, otpRequested]);
  return { seconds, setSeconds };
};

function LoginTimer(props:any) {
  const { seconds } = props;
  return (
    <>
      {seconds <= initialTimeout && seconds > 0 ? (
        <span className='custom-d-block' style={{ marginTop: '-' + '1.5rem', cursor: 'concustom-text-menu', color: '#718096', fontSize: '0.875rem' }}>{`${seconds}`}s</span>
      ) : null}
    </>
  );
}

export { LoginTimer, useTimer };
