import { NotificationsResponse, UpdateNotificationsDTO, UpdateNotificationsResponseDTO } from "../interfaces/notificationsDocument.interface";
import { getRequest, patchRequest } from "../app/postRequest.service";


export function notificationsApi() {
  return new Promise<{ data: NotificationsResponse }>((resolve) =>
    resolve(getRequest('/profile/notifications'))
  );
};

export function updateNotificationsApi(payload : UpdateNotificationsDTO ) {
  return new Promise<{ data: UpdateNotificationsResponseDTO }>((resolve) =>
    resolve(patchRequest('/profile/notifications',payload))
  );
};

