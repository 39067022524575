import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { bankAccountDetailsAsync, getBankAccountDetails, getBankAccountDetailsStatus } from '../reducers/bankAccountDetails.slice';
import Loader from './shared/Loader';
import { BankAccount as BankAccountComponent } from '@mediassistrepo/maven-old';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import '@mediassistrepo/maven-old/dist/style.css';
import { deleteBankAccountApi, fetchChequeLeafApi, updateBankDetailsApi } from '../api/accountAPI';
import { ChequeUploadResponse, UpdateBankDetailsResponse } from '../interfaces/account.interface';
import { getCookieValue, postToNativeApp } from '../common/helpers';
import { beneficiariesAsync, getBeneficiaries, getBeneficiariesLoading } from '../reducers/beneficiaries.slice';
import { uploadKycDoc } from '../common/helpers';
import { useNavigate } from 'react-router';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';

const BankAccount = () => {
  const dispatch = useAppDispatch();
  const bankAccountDetails = useAppSelector(getBankAccountDetails);
  const bankAccountDetailsStatus = useAppSelector(getBankAccountDetailsStatus);
  const [isLoader, setIsLoader] = useState(false as boolean);
  const [isDocUploaded, setIsDocUploaded] = useState(false as boolean);
  const [showAddBankAccount, setshowAddBankAccount] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false as boolean);
  const [isActionMode, setActionMode] = useState(false);
  const [errorMsg, setErrorMsg] = useState("" as string);
  const [chequeResponseData, setchequeResponseData] = useState(null as any);
  const beneficiaryDetails = useAppSelector(getBeneficiaries);
  const areBeneficiariesLoading = useAppSelector(getBeneficiariesLoading);
  const [showAccountDetails, setShowAccountDetails] = useState(false as any);
  const [validation, setValidation] = useState({
    name: "",
    accountNumber: "",
    ifsc: "",
  });
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false as boolean);



  useEffect(() => {
    dispatch(bankAccountDetailsAsync());
    dispatch(beneficiariesAsync());
  }, []);

  const deleteBankDetails = async () => {
    dataLayerPush(Constants.Instance.BANK_ACCOUNT_CTA_INTERACTION, { 
      cta_text: "Delete",
      section_name: "Bank account"
    });
    setIsLoader(true);
    const res = await deleteBankAccountApi();
    if (res.data.isSuccess) { 
      setShowDeleteModal(false)
      setIsLoader(false);
      setshowAddBankAccount(false);
      setActionMode(false)
      setShowAccountDetails(false);
      dispatch(bankAccountDetailsAsync());
      //   setBankAccountDetailsRes([]);
    } else if (!res.data.isSuccess) {
      alert("Something went wrong")
    }
    setIsLoader(false);
  }

  const updateBankDetails = async (chequeResData: any) => {
    setIsLoader(true);
    const payload: any = {
      accountHolderName: chequeResData?.chequeDetails?.name,
      accountNumber: chequeResData?.chequeDetails?.accountNumber,
      bankIFSCCode: chequeResData?.chequeDetails?.ifscCode,
      bankName: chequeResData?.chequeDetails?.bankName,
      branchName: chequeResData?.chequeDetails?.branchName,
      bankAddress: chequeResData?.chequeDetails?.bankAddress,
      chequeleafDocumentID: chequeResData?.docId,
      // claimId: null,
      // priBenefName: null,
      // fileName: null,
      // dbType: null
    };
    try {
      let response = await updateBankDetailsApi(payload);
      if (response.data.isSuccess) {
        setIsDocUploaded(true);
        setErrorMsg("");
        setTimeout(() => {
          setIsDocUploaded(false);
          setshowAddBankAccount(false);
          window.location.reload();
        }, 3000);
      } else if (!response.data.isSuccess) {
        setIsDocUploaded(false);
        setErrorMsg(response.data.error);

      }
    } catch (err) {
      setIsDocUploaded(false);
    }
    setIsLoader(false);
  };

  const fetchChequeLeaf = async () => {
    dataLayerPush(Constants.Instance.BANK_ACCOUNT_CTA_INTERACTION, { 
      cta_text: "View Cheque Leaf",
      section_name: "Bank account"
    });
    const payload = {
      filepath: chequeResponseData?.docId
    }
    try {
      let response = await fetchChequeLeafApi(payload);
      if (response.data.isSuccess && response.data.fileURL) {
        if (getCookieValue('source') === 'native') {
          postToNativeApp({
            browserUrl: response.data.fileURL
          });
        }
        else {
          window.open(response.data.fileURL)
        }
      }
      if (!response.data.isSuccess) {
        // throw new ToastError(response.data.error);
        alert(response.data.error);
      }
    } catch (err) {
      // handleToast(err);
    }
  }

  const uploadCheque = async (files: any) => {
    if (!files) return;
    let docStatus
    let chequeResponse: ChequeUploadResponse = await uploadKycDoc(
      files,
      'kyc/cheque/upload', 'chequeLeaf'
    );
    setIsLoader(false);
    if (chequeResponse && chequeResponse.isSuccess && chequeResponse.docId) {
      docStatus = true;
      setShowAccountDetails(true);
      if (!chequeResponse?.chequeDetails?.name?.trim()) {
        validation.name = "Name is required";
        setIsButtonDisabled(true);
      } else {
        validation.name = "";
      }
      if (!chequeResponse?.chequeDetails?.accountNumber?.trim()) {
        validation.accountNumber = "Account number is required";
        setIsButtonDisabled(true);
      } else {
        validation.accountNumber = "";
      }
      if (!chequeResponse?.chequeDetails?.ifscCode?.trim()) {
        validation.ifsc = "IFSC code is required";
        setIsButtonDisabled(true);
      } else {
        validation.ifsc = "";
      }
      setchequeResponseData(chequeResponse);
      let error = document.getElementById('errorMsg');
      error.style.display = 'none';
    } else {
      docStatus = false;
      let error = document.getElementById('errorMsg');
      error.style.display = 'block';
      error.innerText = 'Error uploading image';
    }
    // gaBankDetails("ProfileBankAccount_UploadDocument", props?.gaData?.benefData[0])
  };

  return (
    <>
      <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Bank Account"} />
      {(bankAccountDetailsStatus == 'loading' || areBeneficiariesLoading) && <Loader />}
      {bankAccountDetails && bankAccountDetails.data &&
        <div className=''>
          <BankAccountComponent
            isLoader={isLoader}
            deleteBankDetails={deleteBankDetails}
            setIsLoader={setIsLoader}
            showAddBankAccount={showAddBankAccount}
            setshowAddBankAccount={setshowAddBankAccount}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            isActionMode={isActionMode}
            setActionMode={setActionMode}
            bankAccountDetailsRes={bankAccountDetails?.data}
            isDocUploaded={isDocUploaded}
            setIsDocUploaded={setIsDocUploaded}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            beneficiaryDetails={beneficiaryDetails?.beneficiaryDetails}
            chequeResponseData={chequeResponseData}
            setchequeResponseData={setchequeResponseData}
            updateBankDetails={updateBankDetails}
            fetchChequeLeaf={fetchChequeLeaf}
            validation={validation}
            setValidation={setValidation}
            isButtonDisabled={isButtonDisabled}
            setIsButtonDisabled={setIsButtonDisabled}
            uploadCheque={uploadCheque}
            showAccountDetails={showAccountDetails}
            setShowAccountDetails={setShowAccountDetails}
            dataLayerPush={dataLayerPush}
          />
        </div>
      }
      {/* <FooterWeb /> */}
    </>

  )
}


export default BankAccount