import { downloadEcardApi, downloadEcardByMemberApi } from "../api/homeAPI";
import { getCookieValue, postToNativeApp } from "../common/helpers";
import { BaseProfileResponse } from "../interfaces/baseProfile.interface";
import { EcardByMemberRequest } from "../interfaces/cashless.interface";

export const downloadEcardFile = async (url: string, handleLoading: Function, baseProfile: BaseProfileResponse) => {
  handleLoading(true);
  const fileName = (baseProfile?.data?.employeeId ? baseProfile?.data?.employeeId : baseProfile?.data?.maid) + '_' + new Date().toISOString().split('T')[0] + '.pdf';
  if (getCookieValue('source') === 'native') {
    const ecardMobileUrl = process.env.REACT_APP_BASE_URL + url;
    handleLoading(false);
    postToNativeApp({
      ecardUrl: ecardMobileUrl,
      fileName: fileName
    });
  } else {
    try {
      const res = await downloadEcardApi(url);
      const _url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
      const a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = _url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(_url);
      handleLoading(false);
    } catch (error) {
      console.log(error);
      handleLoading(false);
    }
  }
};

export const downloadEcardByMember = async (handleLoading: Function, baseProfile: BaseProfileResponse, selectedMaids: Array<string>) => {
  handleLoading(true);
  const fileName = (baseProfile?.data?.employeeId ? baseProfile?.data?.employeeId : baseProfile?.data?.maid) + '_' + new Date().toISOString().split('T')[0] + '.pdf';
  const ecardUrl = `${process.env.REACT_APP_BASE_URL}/claim/ecardByMember?maids=${selectedMaids}&token=${localStorage.getItem("accessToken")}`;
  if (getCookieValue('platform') === 'ios') {
    // const ecardMobileUrl = process.env.REACT_APP_BASE_URL + '/claim/ecardByMember';
    handleLoading(false);

    // postToNativeApp({
    //   ecardUrl: ecardMobileUrl,
    //   fileName: fileName,
    //   payload:selectedMaids
    // });
    // postToNativeApp({ hideFooter: false });
    postToNativeApp({
      browserUrl:ecardUrl
    });
    return true
  } else {
    try {
            
      window.open(ecardUrl, '_blank');
      // const res = await downloadEcardByMemberApi({maids: selectedMaids} as EcardByMemberRequest);
      // const _url = window.URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
      // const a: any = document.createElement("a");
      // document.body.appendChild(a);
      // a.style = "display: none";
      // a.href = _url;
      // a.download = fileName;
      // a.click();
      // window.URL.revokeObjectURL(_url);
      handleLoading(false);
    } catch (error) {
      console.log(error);
      handleLoading(false);
    }
  }
};