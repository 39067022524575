import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { kycDetailsWithMAIDsReqPayload, kycDetailsWithMAIDsResponse } from '../interfaces/familyMemberDetails.interface';
import { kycDetailsApi } from '../api/familyMemberDetailsAPI';

export interface KycDetailsState {
  value: kycDetailsWithMAIDsResponse | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: KycDetailsState = {
  value: null,
  status: 'idle',
};

export const kycDetailsAsync = createAsyncThunk(
  'kycDetails/kycDetailsAsync',
  async (req: kycDetailsWithMAIDsReqPayload) => {
    const response = await kycDetailsApi(req);
    return response.data;
  }
);

export const kycDetailsSlice = createSlice({
  name: 'kycDetails',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(kycDetailsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(kycDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(kycDetailsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = kycDetailsSlice.actions;
export const getKycDetails = (state: RootState) => state.kycDetails.value;
export const getKycDetailsLoading = (state: RootState) => state.kycDetails.status === 'loading';

export default kycDetailsSlice.reducer;
