import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "./shared/Loader";
import { dataLayerPush } from "../utils/gtm";

const HealthBenefitsComponent = () => {
  const navigate = useNavigate();

  const search = window.location.search;
  const token = localStorage.getItem("accessToken");
  const query = new URLSearchParams(search);
  const selectedBenefitName = query.get("benefitName");

  useEffect(() => {
    initializeClaimDetail();
  }, []);

  useEffect(() => {
    const component: any = document.querySelector(
      "health-benefits-web-component"
    );

    if (component) {
      component.addEventListener("gtm-events", (e: any) => {
        e?.detail && dataLayerPush?.(e?.detail[0], e?.detail[1]);
      });
    }
  }, []);

  const initializeClaimDetail = () => {
    const eventQuerry = document.querySelector("health-benefits-web-component");
    eventQuerry?.addEventListener("GO_BACK", (e: any) => {
      navigate(-1);
    });
  };

  return (
    <div style={{ padding: "20px" }}>
        <health-benefits-web-component
          accessToken={token}
          selectedBenefitName={selectedBenefitName}
        ></health-benefits-web-component>
    </div>
  );
};

export default HealthBenefitsComponent;
