import { PolicyDocumentsResponse } from "../interfaces/policyDocuments.interface";
import { getRequest, postRequest} from "../app/postRequest.service";

// A mock function to mimic making an async request for data
export function policyDocumentsApi() {
  return new Promise<{ data: PolicyDocumentsResponse }>((resolve) =>
    resolve(getRequest('/profile/documents'))
  );
}

export function MemberdomiBalApi(payload: any) {
  return new Promise<{ data: any }>((resolve) =>
    resolve(postRequest('/member/domibalance', payload))
  );
}