import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { siProtectApi, siProtectChangeApi } from '../api/accountAPI';
import { SIProtectDetailsRequest, SIProtectResponseDto } from '../interfaces/account.interface';

export interface SIProtectDetailsState {
  value: SIProtectResponseDto | null;
  siProtectChange: SIProtectResponseDto | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: SIProtectDetailsState = {
  value: null,
  siProtectChange: null,
  status: 'idle',
};

export const siProtectDetailsAsync = createAsyncThunk(
  'siProtectDetails/siProtectDetailsAsync',
  async () => {
    const response = await siProtectApi()
    return response.data;
  }
);

export const siProtectChangeAsync = createAsyncThunk(
  'siProtectDetails/siProtectChangeAsync',
  async (req: SIProtectDetailsRequest) => {
    const response = await siProtectChangeApi(req);
    return response.data;
  }
)

export const siProtectDetailsSlice = createSlice({
  name: 'siProtectDetails',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(siProtectDetailsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(siProtectDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(siProtectDetailsAsync.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(siProtectChangeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(siProtectChangeAsync.fulfilled, (state,action) => {
        state.status = 'idle';
        state.siProtectChange =  action.payload
      })
      .addCase(siProtectChangeAsync.rejected, (state) => {
        state.status = 'failed';
      })  
  },
});

export const { } = siProtectDetailsSlice.actions;
export const getSIProtectDetails = (state: RootState) => state.siProtectDetails.value;
export const getSIProtectChangeDetails = (state: RootState) => state.siProtectDetails.siProtectChange;
export const isSIProtectLoading = (state: RootState) => state.siProtectDetails.status === 'loading';

export default siProtectDetailsSlice.reducer;
