import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState} from '../app/store';

import {  fetchFlexPolicies } from '../api/homeAPI';

import { FlexDetailsResponseDTO } from '../interfaces/flexDetails.interface';

export interface FlexDetailsState {
  value: FlexDetailsResponseDTO | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: FlexDetailsState = {
  value: null,
  status: 'idle',
};

export const flexDetailsAsync = createAsyncThunk(
  'flexDetails/flexDetailsAsync',
  async (maid:string) => {
    const response = await fetchFlexPolicies(maid)
    return response.data;
  }
);

export const flexDetailsSlice = createSlice({
  name: 'flexDetails',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(flexDetailsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(flexDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(flexDetailsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = flexDetailsSlice.actions;
export const getflexDetails = (state: RootState) => state.flexDetails.value;
export const areFlexDetailsLoading = (state: RootState ) => state.flexDetails.status === 'loading'; 

export default flexDetailsSlice.reducer;
