
export interface VendorRequest {
  benefitName: string;
}
export interface VendorUrlRequest {
  vendorId: number;
  benefitName: string;
  benefitNameString: string;
  selectedMemberId: string;
}
export interface HealthBenefitsResponse {
    isSuccess: boolean
    benefits: Object
  }
export interface VendoresResponse {
  isSuccess: boolean
  isTCS: boolean
  isHraDone: boolean
  vendorList: Array<vendorListDTO>
}
export interface vendorListDTO {
  vendorName: string
  vendorServiceType: string
  vendorId: number
}
export interface VendorUrlResponse {
  redirectUrlPath: string;
  vendorAPIURL:string;
  isSuccess:boolean;
  headerKeys:Array<string>;
  redirectionURL: string;
  formData: any;
  vendorId?:number | undefined;
}

export interface EcardByMemberRequest {
  maids: Array<string>
}

export interface updateClaimStatus{
  value: string;
  ID: number;
}
export class SurveyJSRequestModel {
  source: string;
  entity_Type: string;
  entity_Id: string;
  entity_Value: EntityValue;
}
export class EntityValue {
  feedback_value: string;
  feedback_rating: number;
}