import { PolicyDocumentsResponse } from "../interfaces/policyDocuments.interface";
import { getRequest, postRequest} from "../app/postRequest.service";
import { VendorUrlFormResponse } from "../interfaces/VendorFormUrlForm.interface";

// A mock function to mimic making an async request for data

export function vendorUrlFormApi(payload: any) {
    return new Promise<{ data: any }>((resolve) =>
      resolve(postRequest('/CorporateConfig/healthBenefit/submitForm', payload))
    );
  }
