import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { fetchCount } from '../api/counterAPI';
import { postRequest } from '../app/postRequest.service';
import { LoginRequest, LoginResponse } from '../interfaces/login';
import { loginApi } from '../api/loginAPI';
import { ActiveIntimationsResponse } from '../interfaces/intimation.interface';
import { activeIntimationApi } from '../api/homeAPI';


export interface activeIntimationsState {
  value: ActiveIntimationsResponse | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: activeIntimationsState = {
  value: null,
  status: 'idle',
};

export const activeIntimationsAsync = createAsyncThunk(
  'activeIntimations/activeIntimationsAsync',
  async () => {
    const response = await activeIntimationApi()
    return response.data;
  }
);

export const activeIntimationsSlice = createSlice({
  name: 'activeIntimations',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(activeIntimationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(activeIntimationsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(activeIntimationsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = activeIntimationsSlice.actions;
export const getactiveIntimations = (state: RootState) => state.activeIntimations.value;
export const areIntimationsLoading = (state: RootState) => state.activeIntimations.status === 'loading';

export default activeIntimationsSlice.reducer;
