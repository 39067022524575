import React, { useEffect, useState } from "react";
import { TermsModal } from "@mediassistrepo/maven-old";
import { captureConsentApi } from "../api/loginAPI";
import { useNavigate } from "react-router-dom";
import { postToNativeApp, getCookieValue } from "../common/helpers";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getCookieValue("platform") == "android") {
      postToNativeApp({ hideFooter: true });
    }
  }, []);

  const handleAccept = async () => {
    setLoading(true);
    try {
      const res = await captureConsentApi();
      window.location.href = "/";
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    if (getCookieValue("platform") == "android") {
      postToNativeApp({ hideFooter: false });
    }
    localStorage.setItem('walkThroughFirstTime','true')
  };

  return (
    <div>
      <TermsModal
        handleCancel={() => {
          navigate("/logout");
        }}
        handleAccept={handleAccept}
        showConsent={true}
        loading={loading}
      />
    </div>
  );
};

export default TermsAndConditions;
