import React, { useEffect, useState } from 'react'
import HeaderWeb from './shared/Header'
import KycWebComponent from './KycWebComponent'
import { useLocation, useNavigate } from 'react-router'
import FooterWeb from './shared/Footer'

const KYC = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [benefDetails, setbenefDetails] = useState(null as any)
  useEffect(() => {
    setbenefDetails(location?.state);
  }, [props])
  
  return (
    <>
      <HeaderWeb isShowBackButton={true} oldHeader={true} name={""} />
      {benefDetails &&
        <div style={{paddingTop: "75px"}}>
          <KycWebComponent maid={location?.state?.maid} benefName={location?.state?.benefName} />
        </div>
      }
      {/* <FooterWeb /> */}


    </>
  )
}

export default KYC

