import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { fetchCount } from '../api/counterAPI';
import { postRequest } from '../app/postRequest.service';
import { LoginRequest, LoginResponse } from '../interfaces/login';
import { loginApi } from '../api/loginAPI';
import { activeClaimsApi } from '../api/homeAPI';

export interface IntimationRequestState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: IntimationRequestState = {
  value: null,
  status: 'idle',
};

export const intimationRequestSlice = createSlice({
  name: 'intimationRequest',
  initialState,
  
  reducers: {
    setIntimationRequest: (state, action: PayloadAction<number>) => {
        state.value = action.payload;
    }
  },
 
});
export const { setIntimationRequest } = intimationRequestSlice.actions;
export const getIntimationRequest = (state: RootState) => state.intimationRequest.value;
export const getIntimationRequestLoading = (state: RootState) => state.intimationRequest.status === 'loading';

export default intimationRequestSlice.reducer;
