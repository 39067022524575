import React, { useState } from 'react';
import NetworkHospital from "./NetworkHospital";
import { useNavigate } from 'react-router-dom';
import HeaderWeb from "./shared/Header";
import { getCookieValue } from '../common/helpers';
import Constants from '../constants/Constants';

const Hospitals = (props: any) => {
    const navigate = useNavigate();
    const nativeApp = getCookieValue('source') === 'native' ? true : false;
    const [policyDuration, setPolicyDuration] = useState<string>("");
    const [policyNumber, setPolicyNumber] = useState<string>("");
    
    return (
        <div>
        {!nativeApp && localStorage.getItem('source') !== Constants.Instance.MEDIBUDDY && <HeaderWeb
          show={false}
          handleEcard={() => props?.setIsEcardOpen(true)}
          showSubmitClaim={props?.showClaimSubmissionModal}

          handleClickClinic={() => { navigate("/claimSubmission/OPD"); navigate(0) }}
          handleClickWellness={() => { navigate("") }}
          policyDuration={policyDuration}
          policyNumber={policyNumber}
          onClose={() => {
            props.setShowClaimSubmissionModal(false)
          }} />}
          <div style={{ padding: '0' }}>
              <div className='px-5 mx-auto' style={{ paddingTop: 20, paddingBottom: 20 }}>
                <NetworkHospital />
            </div>
            </div>
        </div>
    )
}

export default Hospitals;