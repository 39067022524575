import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getAccessToken, getDbType, getMaid, getXrefToken } from "../utils";
import Loader from "./shared/Loader";
import { useAppSelector } from "../app/hooks";
import { getIntimationRequest } from "../reducers/intimationRequest.slice";
import HeaderWeb from "./shared/Header";

export interface Base64Decryption {
    claimID?: number;
    intimationID?: number;
}

function Abha() {
    const navigate = useNavigate();
    const location = useLocation();
    const intimationRequest = useAppSelector(getIntimationRequest);
    const [buttonClick, setButtonClick] = useState(null);
    const [loading, setLoading] = useState(true)
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('accessToken') || getAccessToken();
    const source = searchParams.get('source') || "Maven";
    const dbType =  searchParams.get('dbType') || getDbType();
    const maid = searchParams.get('maid') || parseInt(getMaid());
    const oop = searchParams.get("oop");
    const initializeIntimation = () =>{
        const eventQuerry = document.querySelector("intimation-web-component")
        eventQuerry?.addEventListener('GO_BACK', (e: any) => {
            navigate(-1);
        })
        eventQuerry?.addEventListener('GO_HOME', (e: any) => {
            navigate("/");
        })
    }

    useEffect(() => {
        const component: any = document.querySelector("intimation-web-component");
        if (component) {
            component.event = buttonClick;
            component.addEventListener('back', (e: any) => {
                if (e && e.detail && e.detail.intimationId) {
                    navigate('/',{state: {intimations: true}})
                }
            });
        }
    }, [buttonClick]);

    useEffect(() => {
            const component: any = document.querySelector("intimation-web-component");
            if (component) {
                component.addEventListener('intimation-success', (e: any) => {
                    if (e && e.detail && e.detail.IntimationID) {
                        navigate('/oop?intimationID=' + e.detail.IntimationID)
                    }else if(e && e.detail && e.detail.data && e.detail.data.intimationId){
                        navigate('/oop?intimationID=' + e.detail.data.intimationId)
                    }
                });
            }
        setLoading(false)
        initializeIntimation();
    }, [accessToken && dbType && maid]);

    return (
        <React.Fragment>
            <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024  ?true : false} name={"Create"} />
            {loading && <Loader/>}
            <div style={{marginTop: 80}}>
            <abha-webcomponent
                 accessToken={accessToken}
                 isDownloadAbha={location?.state?.isDownloadAbha}
                 isCreateAbha={location?.state?.isCreateAbha}
                 maid={location?.state?.maid}
                 ></abha-webcomponent>
            </div>
        </React.Fragment>
    );
}

export default Abha;


