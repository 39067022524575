import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { healthBenefitsApi } from '../api/homeAPI';
import { HealthBenefitsResponse } from '../interfaces/cashless.interface';

export interface HealthBenefitsState {
  value: HealthBenefitsResponse | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: HealthBenefitsState = {
  value: null,
  status: 'idle',
};

export const healthBenefitsAsync = createAsyncThunk(
  'healthBenefits/healthBenefitsAsync',
  async () => {
    const response = await healthBenefitsApi()
    return response.data;
  }
);

export const healthBenefitsSlice = createSlice({
  name: 'healthBenefits',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(healthBenefitsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(healthBenefitsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(healthBenefitsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = healthBenefitsSlice.actions;
export const getHealthBenefits = (state: RootState) => state.healthBenefits.value;
export const isHealthBenefitsLoading = (state: RootState) => state.healthBenefits.status === 'loading';

export default healthBenefitsSlice.reducer;
