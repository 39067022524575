
export interface ConfigResponse {
  isSuccess: boolean
  secondaryPolPath: string
  isPolHide: boolean
  isDomiSumhide: boolean
  isFamilysumHide: boolean
  isFamilyBalancesumHide: boolean
  isWellNessEnable: boolean
  isOutpatientServices: boolean
  hideSIProtect: boolean
  hideNetworkHospitalList: boolean
  isHospClaimSubmissionDisabled: boolean
  isEnrolmentEnabled: boolean
  helpContact: string
  helpEmail: string
  isSSOUser: boolean
  isEmailRequired: boolean
  isEmailEditAllowed: boolean
  isEmailVerificationRequired: boolean
  isMobileRequired: boolean
  isMobileEditAllowed: boolean
  isMobileVerificationRequired: boolean
  policyConditions: PolicyCondition[]
  folder: string
  groupId: string
  isLoadFromCAS: boolean
  isControlDisabled: boolean
  isDomiSubmissionEnable: boolean
  isInfinitiEnable: boolean
  isEnableCorporateLogoIneCard: boolean
  enablePastClaimsListing: boolean
  showKYCInClaimSubmission: boolean
  disabledEcardAdvt: boolean
  bannerDetails: BannerDetail[]
  hideIntimation: boolean
  isSBIG: boolean;
  isGenericClaimSubmission:boolean;
  servicesTailoredImages: Record<string,string>;
  opdBenefitsImages: Record<string,string>;
  hideEcard: boolean;
  homePageSectionsOrder: Array<number>;
  faceIdFingerprintActivated:boolean
  handleFormRequestOnUi: boolean
  freshchat: FreshChat;
  showSiProtect:boolean;
}

export class FreshChat {
  widgetUuid: string;
  tags: Array<string>;
  entityName: string;
  userProperties: Array<string>;
  conversationProperties: Array<string>;
  groupId: string;
  folder: string;
  showFreshChatButton: boolean = false;
  freshChatButtonText: string = "Emergency Contact";
}

export class FreshchatInitProps {
  token: string;
  host: string;
  widgetUuid: string;
  tags: Array<string>;
}

export class FreshchatUserProps {
  firstName: string;
  email: string;
  cf_groupfortest: string;
}

export enum UserPropsEnum {
   EMAIL = 'email',
   FIRST_NAME = 'firstName',
   CF_GROUPFORTEST =  'cf_groupfortest'
}

export enum ConversationPropsEnum {
  CORPORATE = 'corporate',
  FOLDER = 'folder'
}

export class FreshchatConversationProps {
  corporate: string;
  folder: string;
}

export interface PolicyCondition {
  corpID: string
  polId: string
  isPolHide: boolean
  isDomiSumhide: boolean
  isDomicilliaryhide: boolean
  isFamilysumHide: boolean
  isFamilyBalancesumHide: boolean
  isMembersiHide: boolean
  showBalanceSum: boolean
}

export interface BannerDetail {
  redirectionUrl: string;
  mobileImageUrl: string;
  desktopImageUrl: string;    
  isExternalLink: boolean;
  CallbackRedirection:string;
}
