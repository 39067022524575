import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SbigUrlApi } from '../api/claimListApi';
import { RootState } from '../app/store';
import { sbiUrlResponse } from '../interfaces/claimList.interface';

export interface SbiUrlState {
  value: sbiUrlResponse | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: SbiUrlState = {
  value: null,
  status: 'idle',
};

export const sbiUrlAsync = createAsyncThunk(
  'sbiUrl/sbiUrlAsync',
  async () => {
    const response = await SbigUrlApi()
    return response.data;
  }
);
  

export const sbiUrlSlice = createSlice({
  name: 'sbiUrl',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(sbiUrlAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sbiUrlAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(sbiUrlAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = sbiUrlSlice.actions;
export const getSbiUrl = (state: RootState) => state.sbiUrl.value;
export const getSbiUrlLoading = (state: RootState) => state.sbiUrl.status === "loading";

export default sbiUrlSlice.reducer;
