import {
    MultiplAccount
  } from '@mediassistrepo/maven-old';
  import "@mediassistrepo/maven-old/dist/style.css";
export default function MultiplAccountComponent(props: any) {


    return (
        <>
            <MultiplAccount />
        </>
    )
}