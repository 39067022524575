import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    IntimationDetails as Intimation
} from '@mediassistrepo/maven-old';
import { getIntimationDetails } from "../api/intimationDetail";
import HeaderWeb from "./shared/Header";
import Loader from "./shared/Loader";

function IntimationDetails() {
    const navigate = useNavigate();
    const params= useParams()
    const intimationId = params.intimationId;
    const [intimationData, setIntimationData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const getIntimationDetailsApi = async() => {
        const payload = {
            accessToken: localStorage.getItem("accessToken"),
            intimationId: intimationId
        }
        const response = await getIntimationDetails(payload);
        if(response && response.data && response.data.intimations && response.data.intimations.length>0){
            setIntimationData(response.data.intimations[0])
        }
        setIsLoading(false)

    }
    useEffect(() => {
        getIntimationDetailsApi()
    },[])
    return (
        <React.Fragment>
            {isLoading && <Loader/>}
            <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Intimation Details"} />
            <Intimation intimationDetails={intimationData} />
        </React.Fragment>
    );
}

export default IntimationDetails;



