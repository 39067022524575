import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { multiUserDataApi, multiUserDetailsApi } from '../api/accountAPI';
import { MultiUserDataResponse, MultiUserDetailsResponse } from '../interfaces/account.interface';

export interface MultiUserDetailsState {
  value: MultiUserDetailsResponse | null;
  data: MultiUserDataResponse | null,
  status: 'idle' | 'loading' | 'failed';
}

const initialState: MultiUserDetailsState = {
  value: null,
  data: null,
  status: 'idle',
};

export const multiUserDetailsAsync = createAsyncThunk(
  'multiUserDetails/multiUserDetailsAsync',
  async () => {
    const response = await multiUserDetailsApi()
    return response.data;
  }
);

export const multiUserDataAsync = createAsyncThunk(
  'multiUserData/multiUserDataAsync',
  async () => {
    const response = await multiUserDataApi();
    return response.data;
  }
);


export const multiUserDetailsSlice = createSlice({
  name: 'multiUserDetails',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(multiUserDetailsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(multiUserDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(multiUserDetailsAsync.rejected, (state) => {
        state.status = 'failed';
      });

      builder
      .addCase(multiUserDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(multiUserDataAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(multiUserDataAsync.rejected, (state) => {
        state.status = 'failed';
      });  
  },
});

export const { } = multiUserDetailsSlice.actions;
export const getMultiUserDetails = (state: RootState) => state.multiUserDetails.value;
export const getMultiUserData = (state: RootState) => state.multiUserDetails.data;

export default multiUserDetailsSlice.reducer;
