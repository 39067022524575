import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { healthBenefitsApi, vendorsApi, vendorUrlApi } from '../api/homeAPI';
import { VendorUrlRequest, VendorUrlResponse } from '../interfaces/cashless.interface';

export interface VendorUrlState {
  value: VendorUrlResponse | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: VendorUrlState = {
  value: null,
  status: 'idle',
};

export const vendorUrlAsync = createAsyncThunk(
  'vendorUrl/vendorUrlAsync',
  async (request:VendorUrlRequest) => {
    const response = await vendorUrlApi(request)
    return response.data;
  }
);

export const vendorUrlSlice = createSlice({
  name: 'vendorUrl',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    clearVendorUrl: (state, action: PayloadAction<number>) => {
      state.value = null;
    },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(vendorUrlAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(vendorUrlAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(vendorUrlAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { clearVendorUrl } = vendorUrlSlice.actions;
export const getvendorUrl = (state: RootState) => state.vendorUrl.value;
export const isVendorUrlLoading = (state: RootState) => state.vendorUrl.status === 'loading';


export default vendorUrlSlice.reducer;
