import { ErrorPage } from "@mediassistrepo/homepage-component";
import HeaderWeb from "./shared/Header";
import { ERRORS, ErrorTYpes } from "../utils/error";
import { useEffect, useState } from "react";

const ErrorComponent = ({ error, resetErrorBoundary }: any) => {
  const [errorType, setErrorType] = useState(ErrorTYpes.GENERIC_ISSUE);

  useEffect(() => {
    switch (true) {
        case error instanceof ERRORS.APIError:
          setErrorType(ErrorTYpes.CONNECTION_ISSUE)
          break;
        case error instanceof ERRORS.ConnectionError:
          setErrorType(ErrorTYpes.CONNECTION_ISSUE)
          break;
        case error instanceof ERRORS.DataError:
          setErrorType(ErrorTYpes.DATA_NOT_FOUND)
          break;
        case error instanceof ERRORS.EmptyPageError:
          setErrorType(ErrorTYpes.EMPTY_PAGE)
          break;
        case error instanceof ERRORS.PageNotFoundError:
          setErrorType(ErrorTYpes.PAGE_NOT_FOUND)
          break;
        default:
          setErrorType(ErrorTYpes.GENERIC_ISSUE);
          break;
      }
  },[]);

  

  return (
    <>
      <HeaderWeb
        isShowBackButton={false}
        oldHeader={true}
      />
      <ErrorPage errorType={errorType} handleButtonClick={resetErrorBoundary} />
    </>
  );
};

export default ErrorComponent;
