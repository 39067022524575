import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { NotificationsResponse } from '../interfaces/notificationsDocument.interface';
import { notificationsApi } from '../api/notificationsAPI';

export interface NotificationsState {
  value: NotificationsResponse | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: NotificationsState = {
  value: null,
  status: 'idle',
};

export const notificationsAsync = createAsyncThunk(
  '/profile/notifications',
  async () => {
    const response = await notificationsApi();
    return response.data;
  }
);

export const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  
  reducers: {
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(notificationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(notificationsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(notificationsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = notificationsSlice.actions;
export const getNotifications = (state: RootState) => state.notifications.value;
export const areNotificationsLoading = (state: RootState) => state.notifications.status == 'loading';

export default notificationsSlice.reducer;
