import React, { useEffect } from "react";
import { RakshaPrimePage } from "@mediassistrepo/homepage-component";
import HeaderWeb from "./shared/Header";
import { useAppSelector } from "../app/hooks";
import {getBeneficiariesLoading, getIsRakshaPrime } from "../reducers/beneficiaries.slice";
import NotFound from "./NotFound";
import Loader from "./shared/Loader";

const RakshaPrime = () => {
  const isRakshaPrime = useAppSelector(getIsRakshaPrime);
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const isLoading = useAppSelector(getBeneficiariesLoading);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  require('@mediassistrepo/homepage-component/dist/style.css');
  return (
    <>
    {isLoading ? < Loader /> : (width <= 768 && isRakshaPrime  ? (
        <div>
          <HeaderWeb className="prime-header" oldHeader={true} isShowBackButton={true} name="Raksha prime" />
          <RakshaPrimePage />
        </div>
      ) : <NotFound />)
      }
    </>
  );
};
export default RakshaPrime;