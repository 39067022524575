import { DecidingFunctionDTO } from "../interfaces/servicesTailored.interface";

const enrollment = (data: DecidingFunctionDTO) => {
  return data?.corporateConfig?.isEnrolmentEnabled;
}

const ecard = (data: DecidingFunctionDTO) => {
  return data?.activeBenefs?.length > 0 && !data?.corporateConfig?.hideEcard;
}

const ecashless = (data: DecidingFunctionDTO) => {
  return !data?.corporateConfig?.hideIntimation;
}

const cashless = (data: DecidingFunctionDTO) => {
  return false;
}

const reimbursement = (data:DecidingFunctionDTO) => {
  return !data?.corporateConfig?.isHospClaimSubmissionDisabled;
}

const hospitals = (data: DecidingFunctionDTO) => {
  return true;
}

const DecidingFunction = {
  enrollment,
  ecard,
  ecashless,
  cashless,
  reimbursement,
  hospitals
}

export default DecidingFunction;