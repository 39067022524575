import { tags } from "./resetPassword";
import content from './linkPolicyContent.json';

export const RedirectionMap = new Map<string,any>([
  [tags.claims, { source: tags.claims, data: { ...content.claim, sourceMethod: tags.claimDetails } }],
  [tags.ecard, { source: tags.ecard, data: content.ecard }],
  [tags.other, { source: tags.other }],
  [tags.reimbursement, { source: tags.reimbursement, data: content.reimbursement }],
  [tags.policyNumber,{ source: tags.policyNumber, data: content.linkPolicy } ],
  [tags.linkPolicy, { source: tags.linkPolicy, data: content.linkPolicy }],
  [tags.intimateHospitalization, { source: tags.intimateHospitalization, data: content.intimateHospital}]
])

