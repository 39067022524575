import React from "react";
import { RedirectionModal as RM } from "@mediassistrepo/homepage-component";

interface PropsType {
  isVisible: boolean;
  toggleBottomModal: any;
  handleCancelClick: any;
  handleConfirmClick: any;
}

const RedirectionModal = (props: PropsType) => {
  const {
    isVisible,
    toggleBottomModal,
    handleCancelClick,
    handleConfirmClick,
  } = props;

  return (
    <RM
      isVisible={isVisible}
      toggleBottomModal={toggleBottomModal}
      handleCancelClick={handleCancelClick}
      handleContinue={handleConfirmClick}
    />
  );
};

export default RedirectionModal;
