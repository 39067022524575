import { BaseResponse } from "./claimList.interface";

export class EncryptedClaimIdResponse extends BaseResponse{
  encrytedClaimId: string;
}

export class EncryptedDataResponse extends BaseResponse{
  encrytedClaimId(arg0: string, encrytedClaimId: any) {
    throw new Error("Method not implemented.");
  }
  encryptedToken: string;
}

export class DecryptedDataResponse extends BaseResponse {
  data: string;
}

export class IntimationCheckRequest {
  IntimationId: number;
}
