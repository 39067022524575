import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { fetchCount } from '../api/counterAPI';
import { postRequest } from '../app/postRequest.service';
import { LoginRequest, LoginResponse } from '../interfaces/login';
import { loginApi } from '../api/loginAPI';
import { bankAccountDetailsResponse } from '../interfaces/account.interface';
import { bankAccountDetailsApi } from '../api/accountAPI';

export interface BankAccountDetailsState {
  value: bankAccountDetailsResponse | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: BankAccountDetailsState = {
  value: null,
  status: 'idle',
};

export const bankAccountDetailsAsync = createAsyncThunk(
  'bankAccountDetails/bankAccountDetailsAsync',
  async () => {
    const response = await bankAccountDetailsApi()
    return response.data;
  }
);

export const bankAccountDetailsSlice = createSlice({
  name: 'bankAccountDetails',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(bankAccountDetailsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(bankAccountDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(bankAccountDetailsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = bankAccountDetailsSlice.actions;
export const getBankAccountDetails = (state: RootState) => state.bankAccountDetails.value;
export const getBankAccountDetailsStatus = (state: RootState) => state.bankAccountDetails.status;

export default bankAccountDetailsSlice.reducer;
