import { ErrorPage } from "@mediassistrepo/homepage-component";
import HeaderWeb from "./shared/Header";
import { ErrorTYpes } from "../utils/error";
import { useNavigate } from "react-router";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderWeb
        isShowBackButton={true}
        oldHeader={true}
        name={"404 Not Found"}
      />
      <ErrorPage errorType={ErrorTYpes.PAGE_NOT_FOUND} handleButtonClick={() => navigate('/')} />
    </>
  );
};

export default NotFound;
