export class TicketResponse {
    helpDeskTicket: HelpdeskTicketResponse | undefined;
    isSuccess: boolean | undefined;
}

export class HelpdeskTicketResponse {
    created_at: Date | undefined;
    description: string | undefined;
    due_by: Date | undefined;
    fr_escalated: boolean | undefined;
    id: number | undefined;
    notes: string[] | undefined;
    priority: number | undefined;
    requester_id: number | undefined;
    source: number | undefined;
    spam: boolean | undefined;
    status: number | undefined;
    subject: string | undefined;
    updated_at: Date | undefined;
    to_emails: string[] | undefined;
    attachments: string[] | undefined;
    tags: string[] | undefined;
    conversations: string[] | undefined
}

export class TicketRequest {
    loginEmail: string | undefined; 
    attemptCount: number | undefined;
    mode: string | undefined;
    loginMobileNumber: string | undefined;
    username: string | undefined; 
    reason: string | undefined;
    employeeId: string | undefined;  
    companyName: string | undefined; 
    policyNumber: string | undefined;
    beneficiaryDob: string | undefined;
    beneficiaryName: string | undefined;
    claimId: string | undefined;
    dateOfAdmission: string | undefined;
    emailForTicket: string | undefined;
    otpContact: string | undefined;

}

export enum TicketTypeEnum {
    PolicyNumber = 'POLICY_NUMBER',
    CorporateEmployee = 'CORPORATE_EMPLOYEE',
    ClaimId = 'CLAIM_ID',
    LoginFailure = 'LOGIN_FAILURE',
    NotMyAccount = 'NOT_MY_ACCOUNT'
 }
 
 export enum TicketRouteEnum {
     UserVerify = 'USER_VERIFY',
     UserIdentify = 'USER_IDENTIFY',
     AuthSignin = 'AUTH_SIGNIN'
 }

 export enum TicketHelpMessage {
    ValidationFailure = 'Oh! We regret that we are not able to find your details even after multiple attempts. Allow us some time, and we can get back to you.',
    LoginFailure = 'Oh! We regret that you are not able to login. Allow us some time, and we can get back to you.',
    NotMyAccount = 'Is it correct that this account does not belong to you?" "Would you like to remove the account by creating a ticket?',

 }

 export enum TicketResponseMessage {
    SuccessMessage = 'We have registered the issue faced by you. Our expert will connect with you within 24 hours. Please check your email for reference.',
    FailureMessage = 'Ticket creation failed. Please try again!'
 }