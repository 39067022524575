import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { healthBenefitsApi, vendorsApi } from '../api/homeAPI';
import { HealthBenefitsResponse, VendoresResponse, VendorRequest } from '../interfaces/cashless.interface';

export interface VendorsState {
  value: VendoresResponse | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: VendorsState = {
  value: null,
  status: 'idle',
};
export const vendorsAsync = createAsyncThunk(
  'vendors/vendorsAsync',
  async (request: VendorRequest) => {
    const response = await vendorsApi(request)
    return response.data;
  }
);

export const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    clearVendors: (state, action: PayloadAction<number>) => {
      state.value = null;
    },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(vendorsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(vendorsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(vendorsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {clearVendors } = vendorsSlice.actions;
export const getvendors = (state: RootState) => state.vendors.value;
export const isVendorLoading = (state: RootState) => state.vendors.status === 'loading';


export default vendorsSlice.reducer;
