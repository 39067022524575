import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { fetchCount } from '../api/counterAPI';
import { postRequest } from '../app/postRequest.service';
import { LoginRequest, LoginResponse } from '../interfaces/login';
import { loginApi } from '../api/loginAPI';
import { ActiveClaimsResponse } from '../interfaces/claim.interface';
import { familyClaims } from '../api/homeAPI';

export interface ActiveClaimsState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ActiveClaimsState = {
  value: null,
  status: 'idle',
};

export const familyClaimsAsync = createAsyncThunk(
  'familyClaims/familyClaimsAsync',
  async () => {
    const response = await familyClaims()
    return response.data;
  }
);

export const familyClaimsSlice = createSlice({
  name: 'familyClaims',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(familyClaimsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(familyClaimsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(familyClaimsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = familyClaimsSlice.actions;
export const getActiveFamilyClaims = (state: RootState) => state.familyClaims.value;

export default familyClaimsSlice.reducer;
