import React from 'react'
import {
    ForgotPassword
  } from '@mediassistrepo/maven-old';
import { resetPasswordApi } from '../api/loginAPI';
import HeaderWeb from './shared/Header';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ResetPasswordRequest } from '../interfaces/login';
import { Helmet } from 'react-helmet-async';

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Parse the query string using URLSearchParams
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get('token');
  const key = queryParams.get('key');
  
  const handleResetPassword = async (data: ResetPasswordRequest) => {
        const res = await resetPasswordApi(data);
        return res?.data;
  }
    
  return (
    <div style={{paddingTop:"70px"}}>
      <Helmet>
        <title>MAven - Forgot password</title>
        <meta name="description" content="" />
      </Helmet>
    <HeaderWeb onBackBtnClick={() => navigate('/')}  isShowBackButton={true} oldHeader={true} name={"Go Back"} />
    <ForgotPassword resetPassword={handleResetPassword}/>
    </div>
  )
}

export default ForgotPasswordComponent