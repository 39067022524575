import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import { TicketRouteEnum, TicketTypeEnum } from "../constants/enum";
import { TicketHelpMessage, TicketResponseMessage } from "../interfaces/ticket.interface";
import { ContactUs, TicketMessage } from '@mediassistrepo/maven-old';
import { createTicket } from "../utils/ticket";
import { dataLayerPush } from "../utils/gtm";
import Constants from "../constants/Constants";
import { Helmet } from "react-helmet-async";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'link-policy-web-component': any;
        }
    }
}

export interface Base64Decryption {
    claimID?: number;
    intimationID?: number;
}
export class sourceDetails {
    id?:number;
    header?: string;
    textFind?:string;
    textDescription?: string;
    textView?: string;
    textFullAccess?: string;
    isClaimRequest?:boolean;
    isEcardRequest ?: boolean;
    isIntimationRequest?:boolean;
    type?:string;
    sourceUrl?:string;
    fullAccessEnabled?:boolean;
}

function NewLinkPolicy(props: any) {
    const navigate = useNavigate();
    const search = window.location.search;
    const location = useLocation();
    const query = new URLSearchParams(search);
    const isEcardRequest = query.get('isEcardRequest');
    const isIntimationRequest = query.get('isIntimationRequest');
    const isClaimRequest = query.get('isClaimRequest');
    const token = props?.token || query.get('q');
    const [showTicket, setShowTicket] = React.useState<boolean>(false);
    const [userName, setUserName] = React.useState<string>(null);
    const [loginMethod, setLoginMethod] = React.useState<string>(null);
    const [error, setError] = React.useState<string>(null);
    const [lsKey, setLsKey] = React.useState<string>(null);
    const [types, setTypes] = React.useState<TicketTypeEnum>(null);
    const [ticketResponse, setTicketResponse] = React.useState<string>('');
    const [ticketRoute, setTicketRoute] = React.useState<any>(null);
    const [sourceData, setsourceData] = React.useState<sourceDetails>({
    });
    const webComponentRef = useRef(null);

    useEffect(() => {
                let obj = new sourceDetails();
                obj.type = "linkPolicy";
                obj.isClaimRequest = (isClaimRequest && isClaimRequest === "true")?true:false;
                obj.isEcardRequest = (isEcardRequest && isEcardRequest === "true")?true:false;
                obj.isIntimationRequest = (isIntimationRequest && isIntimationRequest === "true")?true:false;
                obj.sourceUrl = location?.state?.pageName ?? '/';
                obj.fullAccessEnabled = true;
                if(location?.state?.data){
                    obj.id= location?.state?.data?.id;
                    obj.header= location?.state?.data?.header;
                    obj.textFind= location?.state?.data?.textFind;
                    obj.textDescription= location?.state?.data?.textDescription;
                    obj.textView= location?.state?.data?.textView;
                    obj.textFullAccess= location?.state?.data?.textFullAccess;
                    setsourceData(obj);
                }else{
                    obj.id= 6;
                    obj.header= "Validate yourself";
                    obj.textFind= "Find my policy";
                    obj.textDescription= "";
                    obj.textView= "View your policy";
                    obj.textFullAccess= "Get full access";
                    setsourceData(obj);
                }
               
            }, []);
        
    const hideTicket = () => {
        const event = new CustomEvent('hideTicket');
        document.dispatchEvent(event);
    };

    useEffect(() => {
        setUserName(location.state?.data?.username);
        setLoginMethod(location.state?.data?.loginMethod);
    }, [location]);
   
    
    const createWC =()=>{
        return React.createElement('link-policy-web-component', {
            theme: "theme1",
            source:JSON.stringify(sourceData),
            locale: "en",
            token:token
        });
    }

    const initializeLinkPolicyComp = () =>{
        const eventQuerry = document.querySelector("link-policy-web-component");
        eventQuerry?.addEventListener('GO_BACK', (e: any) => {
            navigate(-1);
        })
    }

    useEffect(()=>{
        webComponentRef.current = document.querySelector("link-policy-web-component");
        webComponentRef?.current?.addEventListener("inputEvent",(e:any)=>{
            dataLayerPush(Constants.Instance.POLICY_FORM_FILL,{
                field_name : e.detail[0], 
                form_type : e.detail[1],
                details_name : e.detail[2],            
            })
        });
        webComponentRef?.current?.addEventListener("ctaEvent",(e:any)=>{
            dataLayerPush(Constants.Instance.POLICY_FORM_CTA_INTERACTION,{
                cta_text : e.detail[0], 
                form_type : e.detail[1],
                details_name : e.detail[2],            
            })
        })
        webComponentRef?.current?.addEventListener("popupEvent",(e:any)=>{
            dataLayerPush(Constants.Instance.POLICY_POPUP_CTA_INTERACTION,{
                cta_text : e.detail[0],
                policy_type : e.detail[1],            
            })
        })
    },[])

    useEffect(() => {
            initializeLinkPolicyComp()
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>MAven - Link your policy</title>
                <meta name="description" content="" />
            </Helmet>
            <div>
                {createWC()}
                {showTicket && <ContactUs
                    type={types}
                    failureRoute={ticketRoute}
                    helpMessage={TicketHelpMessage.ValidationFailure}
                    createTicket={(type: TicketTypeEnum, failureRoute: TicketRouteEnum, email: string, lsKey: string) => createTicket(type, failureRoute, email, userName, error, loginMethod, lsKey).then(res => {
                        if (res?.isSuccess) {
                            hideTicket();
                            setShowTicket(false);
                            setTicketResponse(TicketResponseMessage.SuccessMessage);
                        } else {
                            setTicketResponse(TicketResponseMessage.FailureMessage);
                        }
                        return res;
                    })}
                    lsKey={lsKey}
                    handleClose={() => setShowTicket(false)}
                />}
                {ticketResponse !== '' && <TicketMessage ticketMessage={ticketResponse} handleClose={() => { setTicketResponse(''); setShowTicket(false); }} />}
            </div>
        </React.Fragment>
    );
}

export default NewLinkPolicy;