import { deleteRequest, getRequest, postRequest } from "../app/postRequest.service";
import {  MultiUserDetailsResponse, SIProtectDetailsRequest, SIProtectResponseDto, bankAccountDetailsResponse, UpdateBankDetailsDTO, UpdateBankDetailsResponse, fetchChequeLeafReqDTO, FetchChequeLeafResponse, MultiUserDataResponse } from "../interfaces/account.interface";
import { BaseResponse } from "../interfaces/claimList.interface";
import { RouteConstant } from "../common/common.constants";


// A mock function to mimic making an async request for data
export function siProtectApi() {
  return new Promise<{ data: SIProtectResponseDto }>((resolve) =>
    resolve(getRequest(RouteConstant.SI_PROTECT))
  );
}

export function siProtectChangeApi(payload : SIProtectDetailsRequest){
  return new Promise<{ data: SIProtectResponseDto }>((resolve) =>
    resolve(postRequest(RouteConstant.SI_PROTECT,payload))
  );
}

export function multiUserDetailsApi(){
  return new Promise<{ data: MultiUserDetailsResponse }>((resolve) =>
    resolve(getRequest(RouteConstant.MULTI_USER_CHECK))
  );
}

export function multiUserDataApi(){
  return new Promise<{ data: MultiUserDataResponse }>((resolve) =>
    resolve(getRequest('/profile/multiuser'))
  );
}

export function bankAccountDetailsApi(){
  return new Promise<{ data: bankAccountDetailsResponse }>((resolve) =>
    resolve(getRequest('/profile/bankAccountDetails'))
  );
}

export function deleteBankAccountApi() {
  return new Promise<{ data: BaseResponse }>((resolve) =>
    resolve(deleteRequest('/profile/bankAccountDetails'))
    );
}

export function updateBankDetailsApi(payload: UpdateBankDetailsDTO){
  return new Promise<{ data: UpdateBankDetailsResponse }>((resolve) =>
    resolve(postRequest('/profile/updateBankDetails',payload))
  );
}

export function fetchChequeLeafApi(payload: fetchChequeLeafReqDTO){
  return new Promise<{ data: FetchChequeLeafResponse }>((resolve) =>
    resolve(postRequest('/file/download',payload))
  );
}

export function uploadKycDocApi(payload: any,apiPath: string){
  return new Promise<{ data: any }>((resolve) =>
    resolve(postRequest(apiPath,payload))
  );
}

export function fetchMultiUserFilterDetails(payload: any){
  return new Promise<{ data: any }>((resolve) =>
    resolve(postRequest('/auth/multiuser-filter',payload))
  );
}

export function verifyMultiUserApi(payload: any){
  return new Promise<{ data: any }>((resolve) =>
    resolve(postRequest('/auth/multiuser-verification',payload))
  );
}

export function forgotPasswordApi(payload: any){
  return new Promise<{ data: any }>((resolve) =>
    resolve(postRequest('/auth/forget-password',payload))
  );
}