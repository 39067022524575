import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { bindActionCreators } from "redux";

export default function KycWebComponent(props:any) {
    const dispatch = useDispatch();
    const navigate=useNavigate()
    
    // const { KYCUpdatedDetails } = bindActionCreators(actionCreators, dispatch);
    useEffect(() => {
      const eventQuerry=document.querySelector("kyc-web-component")
      eventQuerry?.addEventListener('upload-kyc', (e:any) => {
           navigate(-1);
        })
      eventQuerry?.addEventListener('login-required', (e:any) => {
          if(e.detail.isSoftLoginRequired){
            navigate(`/auth/${props.claimId}`)
          }
        })
    },[])
    return React.createElement('kyc-web-component', {
        claimId:props?.claimId,
        maid: props?.maid? props.maid:localStorage.getItem('maid'),
        benefName: props?.benefName? props.benefName:localStorage.getItem('benefName'),
        accessToken: localStorage.getItem("accessToken"),

    })
}