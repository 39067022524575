import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getBaseProfile } from '../reducers/baseProfile.slice';
import { getSIProtectChangeDetails, getSIProtectDetails, isSIProtectLoading, siProtectChangeAsync, siProtectDetailsAsync } from '../reducers/siProtect.slice';
import { useNavigate } from 'react-router';
import { getConfig, isConfigLoading } from '../reducers/config.slice';
import { SIProtectDetailsRequest } from '../interfaces/account.interface';
import { SecureTransaction } from '@mediassistrepo/maven-old';
import { config } from 'process';
import Loader from './shared/Loader';
import HeaderWeb from './shared/Header';
import FooterWeb from './shared/Footer';
import { getCookieValue } from '../common/helpers';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';

const SiProtect = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const baseProfile = useAppSelector(getBaseProfile);
  const siDetails = useAppSelector(getSIProtectDetails);
  const corporateConfig = useAppSelector(getConfig);
  const siProtectChangeRes = useAppSelector(getSIProtectChangeDetails);
  const isSIProLoading = useAppSelector(isSIProtectLoading);
  const [showSiProtect, setShowSiProtect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isConfigLoad = useAppSelector(isConfigLoading);

  useEffect(() => {
    dispatch(siProtectDetailsAsync());
  }, [siProtectChangeRes]);

  useEffect(()=>{
    if(corporateConfig){
      setIsLoading(isConfigLoad) 
      if(!corporateConfig?.showSiProtect){
        navigate("/")
      }
      else{
        setShowSiProtect(true);
      }
    }
  },[corporateConfig,isConfigLoad])


  useEffect(() => {
    if (getCookieValue('platform') == 'ios') {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
    else if (getCookieValue('platform') == 'android') {
      document.addEventListener('message', handleMessage);
      return () => {
        document.removeEventListener('message', handleMessage);
      };
    }
  }, []);

  const handleMessage = (event: any) => {
    const message = JSON.parse(event.data);
    if (message.event === 'urlToWeb') {
      if (message.data.includes('claim-list')) {
        navigate("/claim-list")
      }
      else if (message.data.includes('policy-list')) {
        navigate("/policy-list")
      }
      else if (message.data.includes('account')) {
        navigate("/account")
      }
      else {
        navigate("/")
      }
    }
  };

  const handleEditContacts = async (data: any) => {
      navigate(`/updateContact`, { state: { familyData: data } });
  }

  const siProtectChange = (data: SIProtectDetailsRequest) => {
    let cta_text="Subscribe";
    if(!data.subscribe){
      cta_text="UnSubscribe"
    }
    dispatch(siProtectChangeAsync(data));
    dataLayerPush(Constants.Instance.SI_PROTECT_CTA_INTERACTION, { 
      cta_text: cta_text,
    });
  }

  if (isSIProLoading || !baseProfile || !siDetails || !corporateConfig) return <Loader />;


  return (
    <>
    {isLoading && <Loader />}
    {showSiProtect && !isLoading &&
    <>
      <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024 ? true : false} name={"Si Protect"} />
      <div style={{paddingBottom: "100px" }}>
        <SecureTransaction
          baseProfile={baseProfile?.data}
          siDetails={siDetails?.siProtectDetails}
          handleEditContacts={handleEditContacts}
          siProtectChange={siProtectChange}
          config_Details={corporateConfig}
        />
      </div>
      {/* <FooterWeb /> */}
    </>
    }
    </>
  )
}

export default SiProtect