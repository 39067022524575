class APIError extends Error {

    constructor(message: string) {
        super(message);
        

        Object.setPrototypeOf(this, APIError.prototype);
    }
}

class PageNotFoundError extends Error {
    constructor(message: string) {
        super(message);

        Object.setPrototypeOf(this, APIError.prototype);
    }
}

class ConnectionError extends Error {
    constructor(message: string) {
        super(message);

        Object.setPrototypeOf(this, APIError.prototype);
    }
}


class DataError extends Error {
    constructor(message: string) {
        super(message);

        Object.setPrototypeOf(this, APIError.prototype);
    }
}


class EmptyPageError extends Error {
    constructor(message: string) {
        super(message);

        Object.setPrototypeOf(this, APIError.prototype);
    }
}

export enum ErrorTYpes {
  PAGE_NOT_FOUND =  "pageNotFound",
  GENERIC_ISSUE =  "genericIssue",
  CONNECTION_ISSUE =  "connectionIssue",
  DATA_NOT_FOUND = "dataNotFound",
  EMPTY_PAGE =  "emptyPage"
}



export const ERRORS =  {
    APIError,
    PageNotFoundError,
    ConnectionError,
    DataError,
    EmptyPageError
}

