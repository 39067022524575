import React from 'react'
import './Loader.css'; 

const Loader = () =>
  (
    <div className="overlay">
      <div className="loader"></div>
    </div>
  )


export default Loader