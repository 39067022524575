import React, { useEffect, useMemo, useState } from "react";
import {
  getEmailDataApi,
  getMobileDataApi,
  getUpdateContactDetails,
} from "../api/updateContactAPI";
import { ManageProfile as Profile } from "@mediassistrepo/homepage-component";
import Loader from "./shared/Loader";
import { updateContactApi, verifyOtpProfile } from "../api/loginAPI";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getBaseProfile } from "../reducers/baseProfile.slice";
import { getBeneficiaries } from "../reducers/beneficiaries.slice";
import { getLogin, ssoLoginAsync } from "../reducers/login.slice";
import { getMaid, validateEmail, validatePhoneNumber } from "../utils";
import Constants from "../constants/Constants";
import ErrorComponent from "./Error";

interface ContactDetailsResponse {
  isSuccess: boolean;
  data: {
    data: {
      mobileNo: string[];
      emailId: string[];
    };
    isSuccess: boolean;
  };
}

interface FormattedContactInfo {
  mobile: { number: string }[];
  email: { email: string }[];
}
const ManageProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false as boolean);
  const [contactInfo, setContactInfo] = useState<any>({
    mobile: [],
    email: [],
  });
  const [isDeleteModal, setIsDeleteModel] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState({
    heading: "",
    value: "",
  });
  const [optNo, setOtpNo] = useState("");
  const [isResetOtp, setisResetOtp] = useState<boolean>(false);
  const [isOtp, setIsOtp] = useState(false);
  const [mobileInput, setMobileInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [activeTab, SetActiveTab] = useState("");
  const [mobileNoToken, setmobileNoToken] = useState(null as any);
  const [emailOtpToken, setEmailOtpToken] = useState(null as any);
  const baseProfile = useAppSelector(getBaseProfile);
  const benefResponse = useAppSelector(getBeneficiaries);
  const loginResponse = useAppSelector(getLogin);
  const [loginValue, setLoginVaule] = useState<string>("");
  const [isError, setIsError] = useState(false);
  const [isErrorVal, setErrorVal] = useState<boolean>(false);
  const [loginValueMobile, setLoginValueMobile] = useState<any>(
    Constants?.Instance?.loginValueMobileConts
  );
  const [loginValueEmail, setLoginValueEmail] = useState<any>(
    Constants?.Instance?.loginValueEmailConts
  );
  useEffect(() => {
    setIsLoader(true);
    getDetails();
  }, []);

  useEffect(() => {
    if (baseProfile && (contactInfo?.mobile?.length ||contactInfo?.email?.length)) {
      getSSoUser();
    }
  }, [baseProfile,contactInfo]);

  

  useEffect(() => {
    if (loginValueEmail?.isValid) {
      setContactInfo((prev: any) => {
        return {
          email: [
            ...prev?.email?.filter(
              (val: any) => !val?.email.includes(loginValueEmail?.email)
            ),
            {
              email: loginValueEmail?.email,
              isWaring: true,
            },
          ],
          mobile: [...prev?.mobile],
        };
      });
    }
  }, [loginValueEmail?.email]);


  useEffect(() => {
    if (loginValueMobile?.isValid) {
      setContactInfo((prev: any) => {
        return {
          mobile: [
            ...prev?.mobile?.filter(
              (val: any) =>
                !String(val?.number).includes(loginValueMobile?.number)
            ),
            {
              number: loginValueMobile?.mobile,
              isWaring: true,
            },
          ],
          email: [...prev?.email],
        };
      });
    }
  }, [loginValueMobile?.mobile]);

  useEffect(() => {
    const isValid = validatePhoneNumber(loginValue.split("\n")?.[0]);

    if (isValid) {
      setLoginValueMobile({
        mobile: loginValue?.split("\n")?.[0],
        isValid: true,
      });
    }

    const isValidEmail = validateEmail(loginValue?.split("\n")?.[0]);
    if (isValidEmail) {
      setLoginValueEmail({ email: loginValue?.split("\n")?.[0], isValid: true });
    }
  }, [loginValue]);

  const getSSoUser = async () => {
    const res: any = await dispatch(
      ssoLoginAsync({ username: baseProfile?.data?.userName, password: "" })
    );
    if (!res?.payload?.redirectUrl) {
     

      const isValid = validatePhoneNumber(baseProfile?.data?.userName);
      if (isValid) {
        setContactInfo({
          mobile: contactInfo?.mobile?.map((val: any) => {
            if (val?.number.includes(baseProfile?.data?.userName)) {
              return { number: val?.number, isWaring: true };
            } else {
              return val;
            }
          }),
          email: contactInfo?.email,
        });
      } else if (validateEmail(baseProfile?.data?.userName)) {
        setContactInfo({
          mobile: contactInfo?.mobile,
          email: contactInfo?.email?.map((val: any) => {
            if (val?.email.includes(baseProfile?.data?.userName)) {
              return { email: val?.email, isWaring: true };
            } else {
              return val;
            }
          }),
        });
      }
    }
  };

  const getDetails = async () => {
    setIsLoader(true);
    try {
      const response = (await getUpdateContactDetails(
        ""
      )) as ContactDetailsResponse;

      const { data, isSuccess } = response?.data;

      if (isSuccess) {
        const formattedContactInfo: FormattedContactInfo = {
          mobile: data?.mobileNo?.map((number) => ({ number })),
          email: data?.emailId?.map((email) => ({ email })),
        };

        setContactInfo(formattedContactInfo);
        const jsonData = localStorage.getItem("loginValue");
        try {
          if (jsonData) {
            setLoginVaule(jsonData);
          }
        } catch (error) {}
        setIsLoader(false);
      } else {
        setIsError(true);
        setIsLoader(false);
      }
    } catch (error) {
      setIsError(true);
      setIsLoader(false);
    }
  };

  const sendMobileOtps = async (request: any) => {
    setIsLoader(true);
    const response: any = await getMobileDataApi(request);
    if (response?.data?.isSuccess) {
      setIsLoader(false);
      setmobileNoToken(response?.data?.token);
    }
    return response?.data?.isSuccess;
  };

  const sendEmailOtps = async (request: any) => {
    setIsLoader(true);

    try {
      const response = await getEmailDataApi(request);
      if (response) {
        setIsLoader(false);
        setEmailOtpToken(response?.data?.token || "");
      }
      return response?.data?.isSuccess;
    } catch (error) {
      throw error;
    } finally {
      setIsLoader(false);
    }
  };

  const verifyOtpv = async (request: any) => {
    setIsLoader(true);
    try {
      const response = await verifyOtpProfile(request);
      return response?.data;
    } catch (error) {
      return null;
    } finally {
      setIsLoader(false);
      setOtpNo("");
      setisResetOtp(!true);
    }
  };

  const updateContact = async (items:any) => {
    
    setIsLoader(true);
    let maid = getMaid();

    const paylad = {
      maid: maid,
      emailId: items?.email[0]?.email || "",
      phoneNumber: items?.mobile[0].number || "",
      dbType: "MA",
      altEmailId: items?.email[1]?.email || "",
      altMobile: items?.mobile[1]?.number || "",
      isContactsVerified: true,
    };
    try {
      const response = await updateContactApi(paylad);
    } catch (error) {
    } finally {
      setIsLoader(false);
      setOtpNo("");
      setisResetOtp(!true);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {isLoader && <Loader />}
      {!!contactInfo?.mobile?.length && !!contactInfo?.email?.length && (
        <Profile
          isDeleteModal={isDeleteModal}
          setIsDeleteModel={setIsDeleteModel}
          contactInfo={contactInfo}
          setContactInfo={setContactInfo}
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          optNo={optNo}
          setOtpNo={setOtpNo}
          isResetOtp={isResetOtp}
          setisResetOtp={setisResetOtp}
          isOtp={isOtp}
          setIsOtp={setIsOtp}
          mobileInput={mobileInput}
          setMobileInput={setMobileInput}
          emailInput={emailInput}
          setEmailInput={setEmailInput}
          activeTab={activeTab}
          SetActiveTab={SetActiveTab}
          sendEmailOtps={sendEmailOtps}
          sendMobileOtps={sendMobileOtps}
          verifyOtpv={verifyOtpv}
          mobileNoToken={mobileNoToken}
          setmobileNoToken={setmobileNoToken}
          emailOtpToken={emailOtpToken}
          setEmailOtpToken={setEmailOtpToken}
          navigationGoBack={() => navigate(-1)}
          updateContactAPI={updateContact}
          isError={isErrorVal}
          setError={setErrorVal}
        />
      )}
      {isError && <ErrorComponent error="pageNotFound" />}
    </div>
  );
};

export default ManageProfile;
