export interface BeneficiariesResponse {
  isSuccess: boolean
  beneficiaryDetails: BeneficiaryDetail[]
  policyDetails: PolicyDetail[]
  upComingPolicies: any[]
  benefExtraDetails:any[]
}

export interface BeneficiaryDetail {
  sourceDBType: string
  userId: number
  benefPrimaryId: number
  primaryBenefEmpCode: string
  benefName: string
  isActive: boolean
  relationToPrimaryId: number
  relationName: string
  relationSex: string
  relationOrder: number
  mediAssistId: number
  policyId: number
  policyNumber: string
  policyTypeId: number
  policyTypeSubId: number
  policyStartDate: string
  policyEndDate: string
  policyInsCompId: number
  insuranceCompanyName: string
  insuranceCompCode: string
  pol_Category: number
  pol_CategoryName: string
  sumInsured: number
  totalSumInsured: number
  polCorporateId: number
  benefDomLimit: number
  benefZone: string
}

export interface PolicyDetail {
  benefDetail: BenefDetail[]
  policyId: number
  isActive: boolean
  isFuturePolicy: boolean
  policyNumber: string
  polHolderName: string
  policyTypeId: number
  policyTypeSubId: number
  policyStartDate: string
  policyEndDate: string
  policyInsCompId: number
  polFloater: boolean
  polDomiFloater: boolean
  polInsurerPolNo: string
  insuranceCompanyName: string
  insuranceCompCode: string
  pol_Category: number
  pol_CategoryName: string
  sumInsured: number
  totalSumInsured: number
  benefDomLimit: number
  polMixedFloater: boolean
  polDomiAvailable: boolean
  priBenefFloaterSum: number
  nonFloaterOpdMessage: any
  priBenefFloaterSumBalance: number
  isFlex: boolean
  insuranceCompanyShortName: string;
}

export interface BenefDetail {
  benefActive: boolean
  maid: number
  userId: number
  sourceDBType: string
  corporateId: number
  benefName: string
  benefRelOrder: number
  benefRelToPriID: number
  benefRelSex: string
  benefRelName: string
  sumInsured: number
  benifTSA: number
  benefTSABalance: number
  benefIndiVidualDomLimit: number
  benefZone: string
}

export class Insurer{
  name: string;
  shortName: string;
}