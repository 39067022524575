import React from "react";
import { SVGLineAccount, SVGLineClaim, SVGLineHeader, SVGLineHome, SVGLineLinkAccount, SVGLinePolicies, SVGLineStraight } from "../../assets/images/icon";

const topLeft = "position: 'absolute', top:'10%', left:'10%'";
const topMid = "position: 'absolute', top: '10%'";
const topRight = "position: 'absolute', top:'10%', right:'10%'";
const bottomLeft = "position: 'absolute', bottom:'10%', left:'10%'";
const bottomMid = "position: 'absolute', bottom: '14%'";
const bottomRight = "position: 'absolute', bottom:'10%', right:'10%'";
const center = "position: 'absolute', top:'50%', left:'50%', transform: 'translate(-50%,-50%)'";

export const FooterSteps = [
  {
    id: 0,
    title: "Home",
    content: "Effortlessly access all your in-app activities",
    contentPosition: {position: 'absolute', bottom: '10%'},
    svg: <SVGLineHome style={{position:'relative', bottom: '0px', left: '-50%'}} />
  },
  {
    id: 1,
    title: "Claims",
    content: "This section will guide you through your active and past claims",
    contentPosition: {position: 'absolute', bottom: '10%'},
    svg: <SVGLineClaim style={{position:'relative', bottom: '0px', left: '-10%'}} />
  },
  {
    id: 2,
    title: "Actions",
    content: "Submit claim & Intimation add member, download e-card, search hospitals",
    contentPosition: {position: 'absolute', bottom: '16%'},
    svg: <SVGLineStraight style={{position:'relative', left: '50%', top: '50%', transform: 'translate(-50%,0%)'}} />
  },
  {
    id: 3,
    title: "Policies",
    content: "Check your policies, coverage, and balance details",
    contentPosition: {position: 'absolute', bottom: '10%'},
    svg: <SVGLinePolicies style={{position:'relative', bottom: '0px', right: '-50%'}} />
  },
  {
    id: 4,
    title: "Account",
    content: "Personal info, policy details, insurance information overview.",
    contentPosition: {position: 'absolute', bottom: '10%'},
    svg: <SVGLineAccount style={{position:'relative', bottom: '0px', right: '-80%'}} />
  }
];

export const MultiUserSteps = [
    {
      id: 0,
      title: "Click on username",
      content: "You can find profile related quick options here.",
      contentPosition: {position: 'absolute', top: '210px'},
      svg: <SVGLineHeader style={{position:'absolute', left:'0%', top:'calc(100% - 340px)', zIndex:'-1'}} />,
    },
    {
      id: 1,
      title: "Switch accounts",
      content: "Easily switch between your Mediassist accounts",
      contentPosition: {position: 'absolute', bottom: '300px'},
      svg: <SVGLineStraight style={{position:'absolute', left:'50%', bottom:'-40%', zIndex:'-1'}} />,
    }
  ]
  export const SingleUserSteps = [
    {
      id: 0,
      title: "Click on username",
      content: "You can find profile related quick options here.",
      contentPosition: {position: 'absolute', top: '210px'},
      svg: <SVGLineHeader style={{position:'absolute', left:'0%', top:'calc(100% - 340px)', zIndex:'-1'}}  />,
    },
    {
      id: 1,
      title: "Link more accounts",
      content: "Add more accounts to your number",
      contentPosition: {position: 'absolute', bottom: '300px'},
      svg: <SVGLineStraight style={{position:'absolute', left:'50%', bottom:'-40%', zIndex:'-1'}}  />,
    }
  ];