import React from 'react';

export enum PlateForm {
    ios = 'MAVEN|IOS',
    android = 'MAVEN|ANDROID',
}
export const getCookieValue = (name:any) => document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
export const useDeviceContext = () => React.useContext(DeviceContext);

const defaultValues = { isMobile: true, platform: 'Maven|Web' };
const DeviceContext = React.createContext(defaultValues);

export default DeviceContext;