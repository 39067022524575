import { getRequest, postRequest } from "../app/postRequest.service";
import { resetOldPasswordPayload, resetPasswordPayload } from "../interfaces/resetPassword.interface";

export function changePasswordWithMobileSendOtp() {
  return new Promise<{ data: any }>((resolve) =>
    resolve(getRequest('/auth/changePasswordWithMobileSendOtp'))
  );
}

export function changePasswordWithEmailSendOtp() {
  return new Promise<{ data: any }>((resolve) =>
    resolve(getRequest('/auth/changePasswordWithEmailSendOtp'))
  );
}


export function resetOldPasswprd(req: resetOldPasswordPayload) {
  return new Promise<{ data: any }>((resolve) =>
    resolve(postRequest('/profile/password-change',req))
  );
}


export function resetPasswordWithOtp(req: resetPasswordPayload) {
  return new Promise<{ data: any }>((resolve) =>
    resolve(postRequest('/auth/changePassword',req))
  );
}