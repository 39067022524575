import React from 'react'
import ABHAMobileBanner_sm from '../../assets/images/ABHAMobileBanner_sm.png'
import ABHAabBanner from '../../assets/images/ABHATabBanner.png'
import ABHADesktopBanner_md from '../../assets/images/ABHADesktopBanner_md.png'
import ABHADesktopBanner_lg from '../../assets/images/ABHADesktopBanner_lg.png'
const ABHABanner = (props:any) => {
  return (
    <React.Fragment>
      <a href='/abha' target="_self" className={`d-inline-block w-full ${props.className}`}>
        <img src={ABHAMobileBanner_sm} alt="Create ABHA" className='custom-tab-d-none custom-md-d-none w-full' />
        <img src={ABHAabBanner} alt="Create ABHA" className='custom-tab-d-inline-block d-none w-full' />
        <img src={ABHADesktopBanner_md} alt="Create ABHA" className='d-none custom-md-d-inline-block custom-lg-d-none w-full' />
        <img src={ABHADesktopBanner_lg} alt="Create ABHA" className='d-none custom-lg-d-inline-block w-full' />
      </a>
    </React.Fragment>  
  )
}
export default ABHABanner;