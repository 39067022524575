import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { siProtectApi } from '../api/accountAPI';
import { BaseProfileResponse } from '../interfaces/baseProfile.interface';
import { baseProfileApi } from '../api/homeAPI';

export interface BaseProfileState {
  value: BaseProfileResponse | null;
  status: 'idle' | 'loading' | 'failed';
  loader: boolean;
}

const initialState: BaseProfileState = {
  value: null,
  status: 'idle',
  loader: true,
};

export const baseProfileAsync = createAsyncThunk(
  'baseProfile/baseProfileAsync',
  async () => {
    const response = await baseProfileApi()
    return response.data;
  }
);

export const baseProfileSlice = createSlice({
  name: 'baseProfile',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(baseProfileAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(baseProfileAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
        state.loader = false;
      })
      .addCase(baseProfileAsync.rejected, (state) => {
        state.status = 'failed';
        state.loader = false;
      });
  },
});

export const { } = baseProfileSlice.actions;
export const getBaseProfile = (state: RootState) => state.baseProfile.value;
export const isBaseProfileLoading = (state: RootState) => state.baseProfile.status === 'loading';
export const baseProfileLoader = (state: RootState) => state.baseProfile.loader;

export default baseProfileSlice.reducer;
