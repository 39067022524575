import React, { useEffect } from 'react'
import Loader from './shared/Loader';
import { useParams } from 'react-router-dom';

const Auth = () => {
    const {param} = useParams();


    useEffect(() => {
       window.location.href = `${window.location.origin}/old1/auth/${encodeURIComponent(param)}`;
   
    },[])
   
     return (
       <Loader/>
     )
}

export default Auth