// nps.models.ts
export class EntityValue {
  feedback_value: Record<string, any>;
  feedback_rating: number;
}

export class NpsResponeModel {
  error: string;
  isSuccess: boolean;
}

export class NpsFeedback {
  source: string;
  entity_Type: string;
  entity_Id: string;
  entity_Value: EntityValue;
}

export interface FeedbackValue {
  remind_later: {
      count: number;
  };
}