import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../app/store';
import { VendorUrlFormRequest, VendorUrlFormResponse } from '../interfaces/VendorFormUrlForm.interface';
import { vendorUrlFormApi } from '../api/vendorUrlFormApi';

export interface VendorUrlFormState {
    value: VendorUrlFormResponse | null;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: VendorUrlFormState = {
    value: null,
    status: 'idle',
};



export const vendorsUrlFormAsync = createAsyncThunk(
    'vendors/vendorsUrlFormAsync',
    async (request: VendorUrlFormRequest) => {
        const response = await vendorUrlFormApi(request)
        return response.data;
    }
);


export const vendorUrlFormSlice = createSlice({
    name: 'vendorsUrlFormAsync',
    initialState,

    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(vendorsUrlFormAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(vendorsUrlFormAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.value = action.payload;
            })
            .addCase(vendorsUrlFormAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },

});

export const getVendorUrlForm = (state: RootState) => state.vendorUrlFrom.value;

export default vendorUrlFormSlice.reducer;
