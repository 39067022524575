import { PolicyDocumentsResponse } from "../interfaces/policyDocuments.interface";
import { getRequest, postRequest} from "../app/postRequest.service";
import { ClaimListRequest, ClaimListResponse, sbiUrlResponse } from "../interfaces/claimList.interface";

// A mock function to mimic making an async request for data
export function claimListApi(request: ClaimListRequest) {
  return new Promise<{ data: ClaimListResponse }>((resolve) =>
    resolve(postRequest('/claimList/family',request))
  );
}

export function SbigUrlApi(){
  return new Promise<{ data: sbiUrlResponse }>((resolve) =>
    resolve(getRequest('/claim/vendorRedirectUrl'))
  );
}
