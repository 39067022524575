import ReactGA from 'react-ga4';

const initializeGA = async () => {
    return ReactGA.initialize([
        {
            trackingId: process.env.REACT_APP_GA_ID,
        },
    ]);
}

const GaEvent = async (shotEventName: string, event: any) => {
    return ReactGA.event(shotEventName, {
        category: event?.category,
        action: event?.action,
        label: event?.label, // optional
        ...event
    });
}

const GaPageView = async (path: any) => {
    ReactGA.send({ hitType: "pageview", page: path, });
}
export {
    initializeGA,
    GaEvent,
    GaPageView
}