import { useEffect } from "react"
import { useAppDispatch } from "../app/hooks";
import { ssoSamlLoginAsync } from "../reducers/login.slice";
import Loader from "./shared/Loader";
import { getAccessToken } from "../utils";
import { useNavigate } from "react-router";
import { postToNativeApp } from "../common/helpers";

export const loginSsoSaml = async (req: any, dispatch:any, callback:string, username:string) => {
    const res = await dispatch(ssoSamlLoginAsync(req))
    if(res && res.payload && res.payload.accessToken){
        postToNativeApp({
            isLinkPolicy:localStorage.getItem('isLinkPolicy') == 'true' ? true : false,
            accessToken: res.payload?.accessToken || null,
            accessTokenExpirationTime: res.payload?.expiry?.toString() || null,
            userDeviceToken: res.payload?.deviceId,
            xRefData: res.payload.xRefData
          });
        if(callback) {
            switch (callback) {
                case 'claims':
                    window.location.href = '/claim-list';
                    break;
                case 'policies':
                    window.location.href = '/policy-list';
                    break;                
                default:
                    window.location.href = '/';
                    break;
            }
            return;
        }
        window.location.reload()
    }else if(username){
        const genLogProp  = {username};
        window.location.href = `/?ssoUserName=${username}`;
    }
    else{
        window.location.href = '/';
    }
}

const SSO = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const callback = new URLSearchParams(window.location.search).get('callback') as string;

    useEffect(() => {
        const url = window.location;
        const token = new URLSearchParams(url.search).get('token') as string;
        if(getAccessToken() && !callback){
            navigate("/")
         }
        const req = { token };
        loginSsoSaml(req, dispatch, callback, "")
    }, [])

    return (
        <>
            <Loader />
        </>
    )
}

export default SSO