
export const tags = {
  tokenExpired: 'TOKEN_EXPIRED',
  accessToken: 'accessToken',
  accessTokenExpirationTime: 'accessTokenExpirationTime',
  recoveryCodeHeader: 'An OTP has been sent to your registered ',
  endingWith: ' ending with ',
  // enterCode: ' Check your mail to enter the code here.',
  singleQuote: '\'',
  mobile: 'MOBILE',
  email: 'EMAIL',
  password: 'PASSWORD',
  success: 'Success!',
  passwordUpdated: 'Your password has updated successfully.',
  continue: 'Continue',
  oops: 'OOPS! Something went wrong. Please try again.',
  policyNotFound:'We could not find any policy with these details.',
  loginEmailNotVerified: 'Given email address is not verified. Login using your existing user name.',
  loginPhoneNotVerified: 'Given mobile number is not verified. Login using your existing user name.',
  policyNumber: 'POLICY_NUMBER',
  corporateEmployee: 'CORPORATE_EMPLOYEE',
  claimId: 'CLAIM_ID',
  contact:'CONTACT',
  sent: 'SENT',
  unsent: 'UNSENT',
  multipleUsers: 'MULTIPLE_USERS',
  otpVerified: 'OTP verified successfully',
  selectUser: 'Select a user to continue',
  mobileContent: ' Please check your device for the message containing the OTP',
  emailContent:' Please check your inbox for the mail containing the OTP',
  ecard:'ecard',
  claims:'claims',
  claimDetails :"CLAIM_DETAILS",
  linkPolicy:'link-policy',
  reimbursement:'reimbursement',
  intimateHospitalization:'intimateHospitalization',
  networkHospital:'networkHospital',
  other:'other'

}