export class ClaimDetailsRequestDTO {
  claimNumber: number;
  dbType: string;
}

export class FetchAllClaimDetails extends ClaimDetailsRequestDTO {
  fetchPreAuthDetails:boolean;
  fetchHospDetails: boolean;
  fetchBankDetails: boolean;
  fetchBillDetails: boolean;
  fetchSettlement: boolean;
  fetchDenialReason: boolean;
  fetchAttachments: boolean;
  fetchClaimActivities: boolean;
  fetchKYCAttachments: boolean;
  fetchIRDocuments : boolean;
  maskedData:boolean
  accessToken:string
}
export class BaseResponse {
  isSuccess: boolean
  error: string
}

export class ClaimDetailsResponseDTO extends BaseResponse {
  claims: ClaimDetailsDTO;
  dbType: string;
  status: number;
}

export class MedicalIRReqDTO
{
  claimId : number;
  dBType : string;
  attachmentURL : string;
  dmsSource : string;
  claimStatusId : number;
  fileAttachments : Array<fileAttachmentDTO>;
}

export class fileAttachmentDTO{
  attachedTime : Date;
  attachmentDocID : any;
  attachmentName : string;
  attachmentNewFileName : string;
  attachmentOriginalPath : string;
  attachmentTransferedPath : string;
  id : number;
  isActive : boolean;
}

export class VerifyUserInputs
{
  claimId : number;
  accDetails : string;
  validatePAN : boolean;
  validateAccNo : boolean;
}

export class VerifyDataResponse extends BaseResponse{}

export class ClaimDetailsDTO extends BaseResponse {
  basicDetails: BasicClaimDetailsDTO;
  hospDetails: BasicClaimHospitalDetails;
  bankDetails: ClaimBankDetails;
  billDetails: Array<BillsDTO>;
  settlement: SettlementDetailsDTO;
  denialReason: Array<DenialClauseDTO>;
  documents: Array<AttachmentsDTO>;
  activities: Array<ClaimActivitiesDTO>;
  preAuthDetails: Array<BasicPreAuthDetailsDTO>;
  deductionDetails: Array<DeductionDetailsDTO>;
  kycAttachments: KYCDetailsResponse;
  irDocs : IRDocsDTO;
  dbType : string;
  
}
export class IRDocsDTO extends BaseResponse{
  medicalDocs : Array<DocumentsDTO>;
  kycDocs : Array<ClaimKYCAttachmentDocuments>;
}


export class KYCDetailsResponse extends BaseResponse {
  maid: number;
  uploadSource: string;
  claimKYCAttachmentDocuments: Array<ClaimKYCAttachmentDocuments>;
}
export class bankAccountDetailsDto{
  claimNumber:number;
  }

export class ClaimKYCAttachmentDocuments {
  maid: number;
  tag: string;
  isRequired: boolean;
  isAvailable: boolean;
  isEdited: boolean;
  documentStatus: string;
  verifiedByUsername: string;
  governmentDocumentTypeID: number;
  documentNumber: string;
  governmentDocumentType: string;
  documentDetails: Array<KYCAttachmentDocumentDetailDTO>;
  
}
export class KYCAttachmentDocumentDetailDTO {
  path: string;
  fileName: string;
  expiryDate: Date;
  digitisedJSON : string;
}

export class ClaimActivitiesDTO {
  forthStatus: string[];
  audtiTrails: AuditTrial[];

}
export class AuditTrial {
  claimStatus: string;
  claimAuditTime: Date;
  remarks: string;
  updatedBy: string;
  claimedAmount: number;
  approvedAmount: number;
  cumulativeAmount: number;
  status: number;
  letterLink: string;
  audittrialid: number;
}
export class SettlementDetailsDTO {
  approvedAmount: number;
  bankName: string;
  bankIFSCCode: string;
  accountNumber: string;
  accountHolderName: string;
  settlementDate: Date;
}

export class DeductionDetailsDTO {
  slNo: number;
  claimId: number;
  deductionId: number;
  remarks: string;
  modifiedOn: Date;
  amount: number;
  deductionType: string;
  sourceDBType: string
}

export class DocumentsDTO {
  clmdocclmid: number;
  clmdocdocid: number;
  clmdocisavailable: number;
  clmdocisrequired: number;
  clmdocremarks: string;
  clmdocName: string;
  clmdocCashless: number;
  modifiedon: Date;
  clmDocSpecialRemarks: string;
  irType: string;
  sourceDBType: string;
}

export class BillsDTO {
  clmBillID: number;
  clmBillClmid: number;
  clmBillCtype: number;
  clmBillFor: string;
  clmBillDate: Date;
  clmBillNo: string;
  clmBillAmt: number;
  clmPayableAmt: number;
  clmdedreasonid: number;
  clmBillCashless: number;
  clmdedreasondesc: string;
  clmnonpayableAmt: number;
  clmMOUDiscount: number;
  clmCoPayDiscount: number;
  chargeType: ChargeTypeDTO
  clmdedreasondesc_internal: string[]
}

export class ChargeTypeDTO {
  chargeTypeId: number;
  chargeType: string;
  chargeTypeIsActive: number;
  chargeTypeShort: string;
  chargeTypePreAuth: string;
  chargeTypePreAuthDisporder: number;
  chargeTypeSTApplicable: boolean;
  modifiedOn: Date;
  sourceDBType: string;
}

export class ClaimBankDetails {
  accoutnHolderName: string;
  ifsccode: string;
  accountNumber: string;
  branchAddress: string;
}

export class BasicClaimHospitalDetails {
  hospName: string;
  hospID: number;
  hospAddr1: string;
  hospAddr2: string;
  city: string;
  state: string;
  location: string;
  hospRohiniCode: string;
  hospPincode: string;
}

export class BasicClaimDetailsDTO {
  encryptedClaimID : string;
  claimID: number;
  claimRecdate: Date;
  claimType: string;
  claimDoa: Date;
  claimDod: Date;
  lastDocumentReceivedDate: Date;
  claimAgingInMinutes: number;
  claimAmount: number;
  claimStatus: string;
  claimNetPayAmount: number;
  claimTypeID: number;
  clmPreAuths: string;
  clmstatusid: number;
  mobileNo:string;
  emailId:string;
  patientName: string;
  patientAge: number;
  patientGender: string;
  patientRelation: string;
  roomType: string;
  doctorName: string;
  doctorRegNo: string;
  treatmentDetails: Array<TreatmentDetails>;
  benefMAID: number;
  claimStatusDesc : string;
  priBenefName: string;
  insurerName:string;
  clmcomprefno : string;
  clmSourceID : number;
  clmApprovedAmount : number;
  tatPredictedDate : {};
  priBenefEmpId : string;
  claimReferenceNo: string;
}

export class TreatmentDetails {
  ailmentName: string;
  ailmentDesc: string;
  ailmentBodySystem: string;
}
export class DenialClauseDTO {
  denialClauseID: string;
  description: string;
  claimID: number;
  modifiedOn: Date;
  sourceDBType: string;
}

export class AttachmentsDTO {
  attachmentid: number;
  attachclmid: number;
  attachfilename: string;
  attachedon: Date;
  attachDocTypeID: number;
  attachTags: string;
  dmsFilePath: string;
  modifiedon: Date
  attachUserName: string;
  attachTagsUserName: string;
  sourceDBType: string;
  dmsAttachmentID: number;
  dmsAttachmentClmid: number;
  dmsAttachmentPath: string;
  dmsAttacheddate: Date;
  dmsAttachFileName: string;
  dmsSource: string;
  isInternal : boolean;
}

export class BasicPreAuthDetailsDTO {
  preAuthid: number;
  preAuthClmid: number;
  preAuthBillAmt: number;
  preAuthApprovedAmt: number;
  preAuthSettledAmt: number;
  preAuthApproverRemarks: string;
  ailmentCode: string;
  ailmentSummary: string;
  paRecDate: Date;
  preAuthEmailId: string;
  preAuthTreatmentId: number;
  preAuthTreatmentName: string;
}

export class BankDetailsVerify
{
  isBankVerified : boolean;
  isPANverified : boolean;
  accountNumber : string;
  panNumber : string;
}

export class FileDetail {
  fileGuid: string
  fileName: string
}


export class userAuthTokenRes extends BaseResponse{
  expiryDateAndTime:string
  
}

export class AadharUploadResponseDTO extends BaseResponse {
  docId: string;
  fileName: string;
  files : any[];
  gender : string;
  pinCode : string;
  number : string;
  address : string;
  dob : string;
  name : string;
}

export class DrivingLicenseResponseDTO extends BaseResponse{
  docId: string;
  fileName: string;
  files : any[];
  issueDate : string;
  dob: string;
  name: string;
  number : string;
  address : string;
  dlType : any[];
}

export class PassportReponseDTO extends BaseResponse{
  docId: string;
  fileName: string;
  files : any[];
  issueDate : string;
  birthDate : string;
  name : string;
  country : any[];
  nationality : string;
  sex : string;
  passportNumber : string;
  fileNumber : string;
  placeOfBirth : string;
}

export class VoterIDResponseDTO extends BaseResponse{
  docId: string;
  fileName: string;
  files : any[];
  epicNumber : string;
  name : string;
  fatherName : string;
  state : string;
  dob : string;
  gender : string;
  ageAsOn : string;
  address : string;
}

export class ImageLivelinessDTO extends BaseResponse{
  score : any;
  quality : any;
  probability : any;
}

export class KYCUploadResponseDTO {
  KYCUploadResponseDTO()
  {
      frontFile : new FileDetail();
      backFile : new FileDetail();
  }
  number : string;
  address : string;
  dob : string;
  name : string;
  fileDetails : FileDetail;
  gender : string;
  dlType : any[];
  kycDocName : string;
  kycTypeId : number;
  nationality : string;
  frontFile : FileDetail;
  backFile : FileDetail;
  isConfirmed : boolean;
  isModalClose : boolean;
  benefNameMatch : boolean;
  showBenefMatchWarning : boolean;
}

export class medicalIRDocuments {
  files : any[];
  isConfirmed : boolean;
  isModalClose : boolean;
}

export class userLoginDetails{
  isUserLoggedIn : boolean;
  isLoginSkipped : boolean;
}

export class NonCMSClaimRequest {
  claimReferenceNumber: string;
  accessToken: string;
}

export class ClaimDocumentsRequest {
  claimNumber: number;
  maskData: boolean;
  dbType: string;
}

export class ClaimDocumentsResponse extends BaseResponse {
  documents: Array<AttachmentsDTO>;
  kycAttachments: KYCDetailsResponse;
}